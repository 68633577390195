import EventIcon from "@mui/icons-material/Event";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import StoreIcon from "@mui/icons-material/Store";
import { Card, CardContent, CardHeader, Grid } from "@mui/material";
import type { ReactNode } from "react";
import ResourceCard from "./ResourceCard.tsx";

const FurtherResourcesCard = (): ReactNode => (
    <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <CardHeader avatar={<FormatListBulletedIcon />} title="Further Resources" />
        <CardContent>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <ResourceCard
                        icon={<EventIcon />}
                        title="Submit an event"
                        url="https://pretalx.furvester.org/fv6/"
                    >
                        Want to host an panel or other kind of event? Submit your proposal now!
                    </ResourceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ResourceCard
                        icon={<HeadphonesIcon />}
                        title="Apply as DJ"
                        url="https://forms.gle/A5qNuQcMB4Dn5jcF7"
                    >
                        If you'd like to play as a DJ at one of our dances, you can apply here!
                    </ResourceCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ResourceCard
                        icon={<StoreIcon />}
                        title="Apply as vendor"
                        url="https://cloud.furvester.org/apps/forms/s/qom7nQ8Jw6d9XKP4YcgR8pRm"
                    >
                        Want to sell in our Dealers' Den? Then apply here!
                    </ResourceCard>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

export default FurtherResourcesCard;
