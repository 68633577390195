import { useEnsuredRegistration } from "@/components/RegistrationProvider/index.ts";
import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import useDialogController from "@/hooks/useDialogController.ts";
import { dateFormatter } from "@/utils/format.ts";
import PersonIcon from "@mui/icons-material/Person";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    List,
    Typography,
} from "@mui/material";
import { countries } from "countries-list";
import { formatAddress } from "localized-address-format";
import type { ReactNode } from "react";
import CheckListItem from "./CheckListItem.tsx";
import EditDetailsDialog from "./EditDetailsDialog.tsx";

const DetailsCard = (): ReactNode => {
    const { person, details, publicListingConsent, speciesCategory } = useEnsuredRegistration();
    const {
        registration: { state },
    } = useRegistrationSpecs();
    const dialogController = useDialogController();

    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardHeader avatar={<PersonIcon />} title="Details" />
            <CardContent>
                <Typography whiteSpace="pre-line">
                    {formatAddress({
                        name: `${person.firstName} ${person.lastName}`,
                        addressLines: [person.streetAddressLineOne, person.streetAddressLineTwo],
                        locality: person.city,
                        administrativeArea: person.stateOrRegion,
                        postalCode: person.postalCode,
                        postalCountry: person.countryCode,
                    }).join("\n")}
                    <br />
                    {countries[person.countryCode].name}
                </Typography>

                <Typography mt={2}>
                    Date of birth: {person.dateOfBirth.format(dateFormatter)}
                    {person.telegramUsername !== "" && (
                        <>
                            <br />
                            Telegram: @{person.telegramUsername}
                        </>
                    )}
                    {speciesCategory && (
                        <>
                            <br />
                            Species category: {speciesCategory.name}
                        </>
                    )}
                </Typography>

                <List disablePadding sx={{ mt: 2 }}>
                    <CheckListItem
                        checked={details.fursuiter}
                        checkedLabel="You are a fursuiter"
                        uncheckedLabel="You are no fursuiter"
                    />
                    <CheckListItem
                        checked={details.firstFurryCon}
                        checkedLabel="This is your first furry con"
                        uncheckedLabel="This isn't your first furry con"
                    />
                    <CheckListItem
                        checked={publicListingConsent}
                        checkedLabel="You are being listed on our website"
                        uncheckedLabel="You are not being listed on our website"
                    />
                </List>
            </CardContent>
            <CardActions sx={{ mt: "auto" }}>
                <Button
                    disabled={state === "over"}
                    onClick={() => {
                        dialogController.open();
                    }}
                >
                    Update your details
                </Button>
            </CardActions>

            {dialogController.mount && (
                <EditDetailsDialog dialogProps={dialogController.dialogProps} />
            )}
        </Card>
    );
};

export default DetailsCard;
