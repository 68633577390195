import ConfirmDialog from "@/components/ConfirmDialog/index.ts";
import { useDeleteWaitingSpotMutation } from "@/mutations/waiting-spot.ts";
import type { WaitingSpot } from "@/queries/waiting-spot.ts";
import { getErrorMessage } from "@/utils/api.ts";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline.js";
import { Chip, IconButton, ListItem, type SxProps, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { type ReactNode, useMemo } from "react";
import { useConfirm } from "react-confirm-hook";
import RoomTypeListItemText from "../../RoomTypeListItemText.tsx";

type Props = {
    waitingSpot: WaitingSpot;
};

const WaitingSpotListItem = ({ waitingSpot }: Props): ReactNode => {
    const deleteMutation = useDeleteWaitingSpotMutation();
    const confirm = useConfirm(ConfirmDialog);
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = () => {
        confirm({
            title: "Confirm Removal",
            message: (
                <>
                    You are about to delete your waiting spot for {waitingSpot.roomType.name} (
                    {waitingSpot.roomType.hotel.name}). If you later rejoin the waiting list again,
                    you'll be at the back. Do you want to continue?
                </>
            ),
            onConfirm: async () => {
                try {
                    await deleteMutation.mutateAsync({
                        id: waitingSpot.id,
                    });
                    enqueueSnackbar("You have been removed from the waiting list for this room", {
                        variant: "success",
                    });
                } catch (error) {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                }
            },
        });
    };

    const chip = useMemo(() => {
        const sx: SxProps = { mr: 2, width: 50 };
        const chances = Math.max(0, waitingSpot.unpaidLeft - (waitingSpot.position - 1));

        if (chances === 0) {
            return (
                <Tooltip title="There are no rooms left for your position">
                    <Chip label={waitingSpot.position} color="error" sx={sx} />
                </Tooltip>
            );
        }

        const title =
            chances === 1
                ? "One room may become available for your position"
                : `Up to ${chances} rooms may become available for your position`;

        if (chances < 5) {
            return (
                <Tooltip title={title}>
                    <Chip label={waitingSpot.position} color="warning" sx={sx} />
                </Tooltip>
            );
        }

        if (chances < 10) {
            return (
                <Tooltip title={title}>
                    <Chip label={waitingSpot.position} color="info" sx={sx} />
                </Tooltip>
            );
        }

        return (
            <Tooltip title={title}>
                <Chip label={waitingSpot.position} color="success" sx={sx} />
            </Tooltip>
        );
    }, [waitingSpot.position, waitingSpot.unpaidLeft]);

    return (
        <ListItem disablePadding>
            {chip}
            <RoomTypeListItemText roomType={waitingSpot.roomType} />
            <IconButton onClick={handleDelete}>
                <RemoveCircleOutlineIcon />
            </IconButton>
        </ListItem>
    );
};

export default WaitingSpotListItem;
