import useApiFetch from "@/hooks/useApiFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

type CreateReservationValues = {
    roomTypeId: string;
};

const createRelationships = (roomTypeId: string) => ({
    roomType: {
        data: {
            id: roomTypeId,
            type: "room_type",
        },
    },
});

export const useCreateRoomReservationMutation = (): UseMutationResult<
    void,
    Error,
    CreateReservationValues
> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/room-reservation"),
                {
                    method: "POST",
                    body: JSON.stringify({
                        data: {
                            type: "room_reservation",
                            relationships: createRelationships(values.roomTypeId),
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: ["registration", "self", "room-reservation"],
                }),
                queryClient.invalidateQueries({
                    queryKey: ["registration", "self", "waiting-spots"],
                }),
            ]);
        },
    });
};

type UpdateReservationValues = CreateReservationValues;

export const useUpdateRoomReservationMutation = (): UseMutationResult<
    void,
    Error,
    UpdateReservationValues
> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/room-reservation"),
                {
                    method: "PATCH",
                    body: JSON.stringify({
                        data: {
                            type: "room_reservation",
                            id: "self",
                            relationships: createRelationships(values.roomTypeId),
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self", "room-reservation"],
            });
        },
    });
};

export const useDeleteRoomReservationMutation = (): UseMutationResult<void, Error, void> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/room-reservation"),
                {
                    method: "DELETE",
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self", "room-reservation"],
            });
        },
    });
};

type UpgradeRoomValues = {
    attributes: {
        capacity: number | null;
    };
};

export const useChangeRoomCapacityMutation = (): UseMutationResult<
    void,
    Error,
    UpgradeRoomValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/room-reservation/capacity-change"),
                {
                    method: "POST",
                    body: JSON.stringify({
                        data: {
                            type: "room_reservation_capacity_change",
                            ...values,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self", "room-reservation"],
            });
        },
    });
};

type JoinRoomValues = {
    registrationNumber: number;
};

export const useJoinRoomMutation = (): UseMutationResult<void, Error, JoinRoomValues> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    `/registration/registrations/self/room-reservation-invites/${values.registrationNumber}/accept`,
                ),
                {
                    method: "POST",
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: ["registration", "self", "room-reservation"],
                }),
                queryClient.invalidateQueries({
                    queryKey: ["registration", "self", "waiting-spots"],
                }),
            ]);
        },
    });
};

export const useLeaveRoomMutation = (): UseMutationResult<void, Error, void> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/room-reservation/leave"),
                {
                    method: "POST",
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self", "room-reservation"],
            });
        },
    });
};

type ToggleStayDurationValues = {
    attributes: {
        arrival?: number;
        departure?: number;
    };
};

export const useToggleStayDurationMutation = (): UseMutationResult<
    void,
    Error,
    ToggleStayDurationValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/room-reservation/stay-duration-change"),
                {
                    method: "POST",
                    body: JSON.stringify({
                        data: {
                            type: "room_reservation_stay_duration_change",
                            ...values,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self", "room-reservation"],
            });
        },
    });
};

type TransferOwnershipValues = {
    roomMateId: string;
};

export const useTransferOwnershipMutation = (): UseMutationResult<
    void,
    Error,
    TransferOwnershipValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    `/registration/registrations/self/room-reservation/room-mates/${values.roomMateId}/transfer-ownership`,
                ),
                {
                    method: "POST",
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self", "room-reservation"],
            });
        },
    });
};

type InviteRoomMateValues = {
    attributes: {
        registrationNumber: number;
    };
};

export const useInviteRoomMateMutation = (): UseMutationResult<
    void,
    Error,
    InviteRoomMateValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/room-reservation/room-mates"),
                {
                    method: "POST",
                    body: JSON.stringify({
                        data: {
                            type: "room_mate",
                            ...values,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self", "room-reservation"],
            });
        },
    });
};

type KickRoomMateValues = {
    id: string;
};

export const useKickRoomMateMutation = (): UseMutationResult<void, unknown, KickRoomMateValues> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/registration/registrations/self/room-reservation/room-mates/${values.id}`),
                {
                    method: "DELETE",
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self", "room-reservation"],
            });
        },
    });
};
