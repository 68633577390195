import { useRefreshPaymentIntentStatusMutation } from "@/mutations/payment-intent.ts";
import type { PaymentIntentStatus, PaymentIntentStatusOnly } from "@/queries/payment-intent.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import type { Finisher } from "./utils.ts";

const maxWait = 30_000;
const interval = 1_000;
const endStates: PaymentIntentStatus[] = [
    "succeeded",
    "processing",
    "canceled",
    "requires_payment_method",
];

const usePaymentIntentPolling = (onInit: () => void, onFinish: Finisher): void => {
    const [searchParams, setSearchParams] = useSearchParams();
    const polling = useRef(false);
    const refreshMutation = useRefreshPaymentIntentStatusMutation();

    useEffect(() => {
        let timeout: number | null = null;
        let canceled = false;

        const pollPaymentIntentStatus = async (paymentIntentId: string, startTime: number) => {
            let paymentIntent: PaymentIntentStatusOnly;

            try {
                paymentIntent = await refreshMutation.mutateAsync({
                    id: paymentIntentId,
                });
            } catch (error) {
                onFinish({ type: "failure", message: getErrorMessage(error) });
                return;
            }

            if (
                endStates.includes(paymentIntent.status) ||
                Date.now() >= startTime + maxWait ||
                canceled
            ) {
                onFinish({ type: "payment_intent_status", status: paymentIntent.status });
                return;
            }

            timeout = window.setTimeout(
                pollPaymentIntentStatus,
                interval,
                paymentIntentId,
                startTime,
            );
        };

        const paymentIntentId = searchParams.get("payment_intent");

        if (paymentIntentId && !polling.current) {
            polling.current = true;
            setSearchParams({});
            onInit();
            void pollPaymentIntentStatus(paymentIntentId, Date.now());
        }

        return () => {
            canceled = true;

            if (timeout) {
                window.clearTimeout(timeout);
            }
        };
    }, [refreshMutation, onInit, onFinish, searchParams, setSearchParams]);
};

export default usePaymentIntentPolling;
