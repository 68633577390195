import { type RoomType, useRoomTypesQuery } from "@/queries/room-type.ts";
import { LoadingButton } from "@mui/lab";
import { type ButtonProps, Divider, Menu, MenuItem } from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { type ReactNode, useMemo } from "react";
import RoomTypeListItemText from "./RoomTypeListItemText.tsx";

type Props = {
    label: string;
    emptyLabel?: string;
    buttonProps?: ButtonProps;
    loading?: boolean;
    onSelect: (roomType: RoomType) => void;
    excludedRoomTypes?: Pick<RoomType, "id">[];
    excludeUnavailable?: boolean;
    requiredCapacity?: number;
    onlyDirectBooking?: boolean;
};

const RoomTypeButton = ({
    label,
    emptyLabel,
    buttonProps,
    loading,
    onSelect,
    excludedRoomTypes,
    excludeUnavailable,
    requiredCapacity,
    onlyDirectBooking,
}: Props): ReactNode => {
    const roomTypesQuery = useRoomTypesQuery();
    const addMenuState = usePopupState({ variant: "popover", popupId: "room-type-popup" });

    const roomTypes = useMemo(() => {
        if (!roomTypesQuery.data) {
            return [];
        }

        const excludedIds = excludedRoomTypes?.map((roomType) => roomType.id) ?? [];

        return roomTypesQuery.data.filter((roomType) => {
            if (
                excludeUnavailable &&
                roomType.regular.available !== undefined &&
                roomType.regular.available <= 0
            ) {
                return false;
            }

            if (excludedIds.includes(roomType.id)) {
                return false;
            }

            if (onlyDirectBooking && roomType.directBookingDisabled) {
                return false;
            }

            if (requiredCapacity === undefined) {
                return true;
            }

            return roomType.regular.capacity >= requiredCapacity;
        });
    }, [
        roomTypesQuery.data,
        excludedRoomTypes,
        excludeUnavailable,
        requiredCapacity,
        onlyDirectBooking,
    ]);

    const menuItems = useMemo(() => {
        const menuItems: ReactNode[] = [];

        for (const roomType of roomTypes) {
            if (menuItems.length > 0) {
                menuItems.push(<Divider key={`divider-${roomType.id}`} />);
            }

            menuItems.push(
                <MenuItem
                    key={roomType.id}
                    onClick={() => {
                        onSelect(roomType);
                        addMenuState.close();
                    }}
                >
                    <RoomTypeListItemText key={roomType.id} roomType={roomType} />
                </MenuItem>,
            );
        }

        return menuItems;
    }, [roomTypes, onSelect, addMenuState.close]);

    if (roomTypesQuery.isError) {
        throw roomTypesQuery.error;
    }

    if (roomTypesQuery.isPending) {
        return (
            <LoadingButton {...buttonProps} loading>
                {label}
            </LoadingButton>
        );
    }

    if (roomTypes.length === 0 && !emptyLabel) {
        return null;
    }

    return (
        <>
            <LoadingButton
                {...buttonProps}
                {...bindTrigger(addMenuState)}
                disabled={roomTypes.length === 0}
                loading={loading}
            >
                {roomTypes.length === 0 ? emptyLabel ?? label : label}
            </LoadingButton>
            <Menu {...bindMenu(addMenuState)} keepMounted>
                {menuItems}
            </Menu>
        </>
    );
};

export default RoomTypeButton;
