import { useRegistration } from "@/components/RegistrationProvider/index.ts";
import type { ReactNode } from "react";
import DashboardPage from "./DashboardPage/index.ts";
import RegistrationPage from "./RegistrationPage/index.ts";

const HomePage = (): ReactNode => {
    const registration = useRegistration();
    return registration ? <DashboardPage /> : <RegistrationPage />;
};

export default HomePage;
