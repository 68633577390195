import useApiFetch from "@/hooks/useApiFetch.ts";
import type { registrationAttributesSchema } from "@/queries/registration.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { JsonApiError, handleJsonApiError } from "jsonapi-zod-query";
import type { z } from "zod";

type CreateRegistrationValues = {
    attributes: Pick<
        z.input<typeof registrationAttributesSchema>,
        "person" | "details" | "publicListingConsent"
    > & {
        sponsorLevel: "silver" | "gold" | null;
        tShirtSize: string | null;
    };
    speciesCategoryId: string | null;
};

const randomSleep = async (): Promise<void> =>
    new Promise((resolve) => window.setTimeout(resolve, 200 + Math.random() * 100));

const createRelationships = (speciesCategoryId?: string | null) => {
    return {
        speciesCategory:
            speciesCategoryId !== undefined
                ? {
                      data: speciesCategoryId
                          ? { type: "species_category", id: speciesCategoryId }
                          : null,
                  }
                : undefined,
    };
};

export const useCreateRegistrationMutation = (): UseMutationResult<
    void,
    Error,
    CreateRegistrationValues
> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const { attributes, speciesCategoryId } = values;
            const body = JSON.stringify({
                data: {
                    type: "registration",
                    attributes,
                    relationships: createRelationships(speciesCategoryId),
                },
            });

            let response: Response | null = null;

            do {
                try {
                    response = await fetch(apiUrl("/registration/registrations"), {
                        method: "POST",
                        body,
                        headers: {
                            "Content-Type": "application/vnd.api+json",
                        },
                    });
                    await handleJsonApiError(response);
                } catch (error) {
                    if (!(error instanceof JsonApiError)) {
                        // Any kind of network error will issue a retry.
                        await randomSleep();
                        continue;
                    }

                    if (error.status >= 500 || error.status === 403) {
                        // Service is overloaded or registration isn't open yet.
                        await randomSleep();
                        continue;
                    }

                    throw error;
                }
            } while (!response?.ok);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self"],
            });
        },
    });
};

type UpdateRegistrationValues = {
    attributes: Partial<
        Pick<
            z.input<typeof registrationAttributesSchema>,
            "person" | "details" | "publicListingConsent"
        >
    > & {
        flagCodes?: string[];
    };
    speciesCategoryId?: string | null;
};

export const useUpdateRegistrationMutation = (): UseMutationResult<
    void,
    Error,
    UpdateRegistrationValues
> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const { attributes, speciesCategoryId } = values;

            const response = await fetch(apiUrl("/registration/registrations/self"), {
                method: "PATCH",
                body: JSON.stringify({
                    data: {
                        type: "registration",
                        id: "self",
                        attributes,
                        relationships: createRelationships(speciesCategoryId),
                    },
                }),
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self"],
            });
        },
    });
};
