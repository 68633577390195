import { useDeleteProductMutation } from "@/mutations/product.ts";
import type { Product } from "@/queries/product.ts";
import { getErrorMessage } from "@/utils/api.ts";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline.js";
import { IconButton } from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";

type Props = {
    product: Product;
};

const DeleteAction = ({ product }: Props): ReactNode => {
    const deleteMutation = useDeleteProductMutation();
    const { enqueueSnackbar } = useSnackbar();

    return (
        <IconButton
            onClick={() => {
                deleteMutation.mutate(
                    { id: product.id },
                    {
                        onError: (error) => {
                            enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                        },
                    },
                );
            }}
            disabled={deleteMutation.isPending}
        >
            <RemoveCircleOutlineIcon />
        </IconButton>
    );
};

export default DeleteAction;
