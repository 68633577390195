import { useChangeRoomCapacityMutation } from "@/mutations/room-reservation";
import type { RoomReservation } from "@/queries/room-reservation.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { LoadingButton } from "@mui/lab";
import { Menu, MenuItem, type SxProps } from "@mui/material";
import { bindMenu, usePopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import type { MouseEvent } from "react";
import { useMemo } from "react";

type Props = {
    reservation: RoomReservation;
    sx?: SxProps;
};

const UpgradeButton = ({ reservation, sx }: Props): JSX.Element | null => {
    const { enqueueSnackbar } = useSnackbar();
    const changeMutation = useChangeRoomCapacityMutation();
    const occupancy = reservation.roomMates.length + 1;
    const popupState = usePopupState({ variant: "popover", popupId: "upgrade-menu" });
    const { roomType } = reservation;

    const availableUpgrades = useMemo(() => {
        if (!reservation.roomType.upgrades) {
            return [];
        }

        return reservation.roomType.upgrades
            .filter(
                (upgrade) =>
                    upgrade.available &&
                    upgrade.available > 0 &&
                    upgrade.capacity > occupancy &&
                    upgrade.capacity !== reservation.upgradedCapacity,
            )
            .map((upgrade) => upgrade.capacity);
    }, [occupancy, reservation]);

    const handleChange = (capacity: number | null) => {
        changeMutation.mutate(
            { attributes: { capacity } },
            {
                onSuccess: () => {
                    enqueueSnackbar(
                        !reservation.upgradedCapacity ||
                            (capacity && capacity > reservation.upgradedCapacity)
                            ? "Your room has been upgraded"
                            : "Your room has been downgraded",
                        { variant: "success" },
                    );
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    const downgradeAvailable =
        reservation.upgradedCapacity && occupancy <= roomType.regular.capacity;

    const handleButtonClick = (event: MouseEvent<HTMLElement>) => {
        if (availableUpgrades.length === 1 && !downgradeAvailable) {
            handleChange(availableUpgrades[0]);
            return;
        }

        popupState.open(event);
    };

    if (availableUpgrades.length === 0) {
        if (downgradeAvailable) {
            return (
                <LoadingButton
                    color="primary"
                    loading={changeMutation.isPending}
                    onClick={() => {
                        handleChange(null);
                    }}
                    sx={sx}
                >
                    Downgrade Room
                </LoadingButton>
            );
        }

        return null;
    }

    return (
        <>
            <LoadingButton
                color="primary"
                loading={changeMutation.isPending}
                onClick={handleButtonClick}
                sx={sx}
            >
                {reservation.upgradedCapacity ? "Change Upgrade" : "Upgrade Room"}
            </LoadingButton>
            <Menu
                {...bindMenu(popupState)}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
            >
                {downgradeAvailable && (
                    <MenuItem
                        onClick={() => {
                            popupState.close();
                            handleChange(null);
                        }}
                    >
                        Downgrade to regular
                    </MenuItem>
                )}
                {availableUpgrades.map((capacity) => (
                    <MenuItem
                        onClick={() => {
                            popupState.close();
                            handleChange(capacity);
                        }}
                        key={capacity}
                    >
                        {reservation.upgradedCapacity && reservation.upgradedCapacity > capacity
                            ? "Downgrade"
                            : "Upgrade"}{" "}
                        to {capacity} persons
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default UpgradeButton;
