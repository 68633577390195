import FlagIcon from "@/components/FlagIcon/index.ts";
import { Box, Chip } from "@mui/material";
import type { TextFieldProps } from "@mui/material";
import { type TCountryCode, countries } from "countries-list";
import { RhfAutocomplete } from "mui-rhf-integration";
import type { Control, FieldPath, FieldValues } from "react-hook-form";

const collator = new Intl.Collator("en-US", { sensitivity: "base" });

export const countryOptions = Object.entries(countries)
    .map(([countryCode, country]) => ({
        countryCode: countryCode as TCountryCode,
        label: country.name,
        token: country.name.toLowerCase(),
    }))
    .sort((a, b) => collator.compare(a.label, b.label));

type Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
    control: Control<TFieldValues>;
    name: TName;
    textFieldProps?: Omit<TextFieldProps, "error" | "onChange" | "onBlur" | "value" | "inputRef">;
    multiple?: boolean;
};

const RhfCountrySelect = <
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    control,
    name,
    textFieldProps,
    multiple,
}: Props<TFieldValues, TName>): JSX.Element => {
    return (
        <RhfAutocomplete
            control={control}
            name={name}
            options={countryOptions}
            freeSolo={false}
            multiple={multiple}
            getOptionLabel={(option) => option.label}
            optionToValue={(option) => option.countryCode}
            valueToOption={(value) => countryOptions.find((option) => option.countryCode === value)}
            filterOptions={(options, state) => {
                if (state.inputValue === "") {
                    return options;
                }

                const token = state.inputValue.toLowerCase();
                return options.filter((option) => option.token.includes(token));
            }}
            renderOption={(props, option) => (
                <Box
                    component="li"
                    sx={{ "& > span": { mr: 2, flexShrink: 0 } }}
                    {...props}
                    key={option.countryCode}
                >
                    <FlagIcon countryCode={option.countryCode} key="icon" />
                    {option.label}
                </Box>
            )}
            renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                    <Chip
                        {...getTagProps({ index })}
                        icon={<FlagIcon countryCode={option.countryCode} sx={{ ml: 1.5 }} />}
                        key={option.countryCode}
                        label={option.label}
                    />
                ));
            }}
            autoHighlight
            textFieldProps={textFieldProps}
        />
    );
};

export default RhfCountrySelect;
