import UserAvatar from "@/components/UserAvatar/index.ts";
import type { RoomMateSearch } from "@/queries/room-mate-search.ts";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    search: RoomMateSearch;
};

const RoomMateSearchCard = ({ search }: Props): ReactNode => {
    const { reservation, content } = search;
    const mainContact = reservation.mainContact;
    const identity = mainContact.identity;

    if (!identity) {
        throw new Error("Identity is null");
    }

    return (
        <Card>
            <CardHeader
                avatar={<UserAvatar identity={identity} />}
                title={identity.nickname}
                subheader={
                    <>
                        {reservation.roomType.name}
                        <br />
                        {reservation.roomType.hotel.name}
                    </>
                }
            />
            <CardContent>
                <Typography whiteSpace="pre-line">{content}</Typography>
            </CardContent>
        </Card>
    );
};

export default RoomMateSearchCard;
