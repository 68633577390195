import { JsonApiError } from "jsonapi-zod-query";

export const apiUrl = (path: string): URL => new URL(path, import.meta.env.VITE_APP_API_URL);

export const getErrorMessage = (error: unknown): string => {
    if (!(error instanceof JsonApiError)) {
        return "An unknown error occurred";
    }

    const jsonApiError = error.errors[0];

    return jsonApiError.detail ?? jsonApiError.title ?? "An unknown error occurred";
};
