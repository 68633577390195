import { apiUrl } from "@/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { createDataSelector, createResourceSelector, handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";

const attributesSchema = z.object({
    clientSecret: z.string().nullable(),
    amount: z.number().int(),
    currency: z.string(),
    status: z.enum([
        "canceled",
        "processing",
        "requires_action",
        "requires_capture",
        "requires_confirmation",
        "requires_payment_method",
        "succeeded",
    ]),
    instructionsUrl: z.string().nullable(),
});

export const paymentIntentSelector = createDataSelector(
    createResourceSelector({
        type: "payment_intent",
        attributesSchema,
    }),
);

export const paymentIntentStatusSelector = createDataSelector(
    createResourceSelector({
        type: "payment_intent",
        attributesSchema: attributesSchema.pick({ status: true }),
    }),
);

export type PaymentIntent = ReturnType<typeof paymentIntentSelector>;
export type PaymentIntentStatusOnly = ReturnType<typeof paymentIntentStatusSelector>;
export type PaymentIntentStatus = PaymentIntent["status"];

export const usePaymentIntentQuery = (id: string): UseQueryResult<PaymentIntent> => {
    return useQuery({
        queryKey: ["registration", "self", "payment_intent", id],
        queryFn: async ({ signal }) => {
            const response = await fetch(
                apiUrl(`/registration/registrations/self/payment-intents/${id}`),
                {
                    signal,
                    credentials: "include",
                    headers: {
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);

            return response.json();
        },
        select: paymentIntentSelector,
    });
};
