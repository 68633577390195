import useApiFetch from "@/hooks/useApiFetch.js";
import {
    type PaymentIntent,
    type PaymentIntentStatusOnly,
    paymentIntentSelector,
    paymentIntentStatusSelector,
} from "@/queries/payment-intent.ts";
import { apiUrl } from "@/utils/api";
import type { UseMutationResult } from "@tanstack/react-query";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

export type CreatePaymentIntentValues = {
    attributes: {
        paymentMethodType: "card" | "sofort" | "bank_transfer";
    };
};

export const useCreatePaymentIntentMutation = (): UseMutationResult<
    PaymentIntent,
    Error,
    CreatePaymentIntentValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/payment-intents"),
                {
                    method: "POST",
                    body: JSON.stringify({
                        data: {
                            type: "payment_intent",
                            ...values,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);

            return paymentIntentSelector(await response.json());
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ["registration", "self"], exact: true }),
                queryClient.invalidateQueries({ queryKey: ["registration", "self", "payments"] }),
            ]);
        },
    });
};

type RefreshPaymentIntentStatusValues = {
    id: string;
};

export const useRefreshPaymentIntentStatusMutation = (): UseMutationResult<
    PaymentIntentStatusOnly,
    Error,
    RefreshPaymentIntentStatusValues
> => {
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(
                    `/registration/registrations/self/payment-intents/${values.id}/status-refresh`,
                ),
                {
                    method: "POST",
                },
            );
            await handleJsonApiError(response);

            return paymentIntentStatusSelector(await response.json());
        },
    });
};

type CancelPaymentIntentValues = {
    id: string;
};

export const useCancelPaymentIntentMutation = (): UseMutationResult<
    void,
    Error,
    CancelPaymentIntentValues
> => {
    const queryClient = useQueryClient();
    const fetch = useApiFetch();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/registration/registrations/self/payment-intents/${values.id}`),
                {
                    method: "DELETE",
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ["registration", "self"], exact: true }),
                queryClient.invalidateQueries({ queryKey: ["registration", "self", "payments"] }),
            ]);
        },
    });
};
