import type { InputBaseComponentProps } from "@mui/material";
import { TextField } from "@mui/material";
import type {
    CardCvcElementComponent,
    CardExpiryElementComponent,
    CardNumberElementComponent,
} from "@stripe/react-stripe-js";
import type { StripeElementChangeEvent } from "@stripe/stripe-js";
import type { ElementType, ReactNode } from "react";
import { useState } from "react";
import StripeInput from "./StripeInput.tsx";

type Props = {
    component: CardNumberElementComponent | CardExpiryElementComponent | CardCvcElementComponent;
    label: string;
};

const StripeElementWrapper = ({ component, label }: Props): ReactNode => {
    const [focused, setFocused] = useState(false);
    const [empty, setEmpty] = useState(true);
    const [error, setError] = useState<string | null>(null);

    const handleChange = (event: StripeElementChangeEvent) => {
        setError(event.error ? event.error.message : null);
        setEmpty(event.empty);
    };

    const labelShrunk = focused || !empty;

    return (
        <TextField
            fullWidth
            label={label}
            error={Boolean(error)}
            required
            helperText={error}
            InputLabelProps={{ shrink: labelShrunk }}
            InputProps={{
                inputProps: {
                    component,
                    onRemoteChange: handleChange,
                    onRemoteFocus: () => {
                        setFocused(true);
                    },
                    onRemoteBlur: () => {
                        setFocused(false);
                    },
                },
                inputComponent: StripeInput as unknown as ElementType<InputBaseComponentProps>,
            }}
        />
    );
};

export default StripeElementWrapper;
