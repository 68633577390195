import { Card, CardActionArea, CardContent, CardHeader } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    icon: ReactNode;
    title: string;
    url: string;
    children: ReactNode;
};

const ResourceCard = ({ icon, title, url, children }: Props) => (
    <Card sx={{ height: "100%" }} variant="outlined">
        <CardActionArea
            onClick={() => {
                window.open(url, "_blank", "noreferrer");
            }}
        >
            <CardHeader avatar={icon} title={title} />
            <CardContent>{children}</CardContent>
        </CardActionArea>
    </Card>
);

export default ResourceCard;
