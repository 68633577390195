import FullPageSpinner from "@/components/FullPageSpinner/index.ts";
import { type Registration, useSelfRegistrationQuery } from "@/queries/registration.ts";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";

const RegistrationContext = createContext<Registration | null | undefined>(undefined);

type Props = {
    children: ReactNode;
};

const RegistrationProvider = ({ children }: Props): ReactNode => {
    const registrationQuery = useSelfRegistrationQuery();

    if (registrationQuery.isPending) {
        return <FullPageSpinner />;
    }

    if (registrationQuery.isError) {
        throw registrationQuery.error;
    }

    return (
        <RegistrationContext.Provider value={registrationQuery.data}>
            {children}
        </RegistrationContext.Provider>
    );
};

export const useRegistration = (): Registration | null => {
    const context = useContext(RegistrationContext);

    if (context === undefined) {
        throw new Error("Context used outside provider");
    }

    return context;
};

export const useEnsuredRegistration = (): Registration => {
    const registration = useRegistration();

    if (!registration) {
        throw new Error("Unable to ensure registration");
    }

    return registration;
};

export default RegistrationProvider;
