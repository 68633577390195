import type { ControlledDialogProps } from "@/hooks/useDialogController.ts";
import { useJoinRoomMutation } from "@/mutations/room-reservation";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    registrationNumber: z
        .string()
        .regex(/^\d{1,4}$/, "Invalid number")
        .transform((value) => Number.parseInt(value, 10)),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
};

const JoinFormDialog = ({ dialogProps }: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const joinMutation = useJoinRoomMutation();

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });

    const handleSubmit = (values: TransformedValues) => {
        joinMutation.mutate(values, {
            onSuccess: () => {
                enqueueSnackbar("You successfully joined the room", { variant: "success" });
                dialogProps.onClose();
            },
            onError: (error) => {
                enqueueSnackbar(getErrorMessage(error), { variant: "error" });
            },
        });
    };

    return (
        <Dialog
            {...dialogProps}
            PaperProps={{
                component: "form",
                onSubmit: form.handleSubmit(handleSubmit),
                noValidate: true,
            }}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>Join Room Reservation</DialogTitle>
            <DialogContent dividers>
                <DialogContentText sx={{ mb: 2 }}>
                    If the owner of a room reservation has invited you, you can accept the invite by
                    entering their registration number here.
                </DialogContentText>
                <RhfTextField
                    control={form.control}
                    name="registrationNumber"
                    label="Registration Number"
                    required
                    fullWidth
                    inputProps={{
                        maxLength: 4,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={dialogProps.onClose}
                    disabled={joinMutation.isPending}
                    color="inherit"
                >
                    Cancel
                </Button>
                <LoadingButton loading={joinMutation.isPending} type="submit" color="primary">
                    Join
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default JoinFormDialog;
