import type { ControlledDialogProps } from "@/hooks/useDialogController.ts";
import { useRoomMateSearchesQuery } from "@/queries/room-mate-search.ts";
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import type { ReactNode } from "react";
import RoomMateSearchCard from "./RoomMateSearchCard.tsx";

type Props = {
    dialogProps: ControlledDialogProps;
};

const RoomMateSearchesDialog = ({ dialogProps }: Props): ReactNode => {
    const searchesQuery = useRoomMateSearchesQuery();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    if (searchesQuery.isError) {
        throw searchesQuery.error;
    }

    if (searchesQuery.isPending) {
        return (
            <Backdrop open>
                <CircularProgress />
            </Backdrop>
        );
    }

    return (
        <Dialog {...dialogProps} maxWidth="sm" fullWidth fullScreen={fullScreen}>
            <DialogTitle>Room Mate Search</DialogTitle>
            <DialogContent dividers>
                {searchesQuery.data.length === 0 ? (
                    <Typography>Nobody is looking for a room mate right now.</Typography>
                ) : (
                    <Stack spacing={2}>
                        {searchesQuery.data.map((search) => (
                            <RoomMateSearchCard search={search} key={search.id} />
                        ))}
                    </Stack>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dialogProps.onClose();
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RoomMateSearchesDialog;
