import { apiUrl } from "@/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { createDataSelector, createResourceSelector, handleJsonApiError } from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const attributesSchema = z.object({
    publicStripeKey: z.string(),
    dates: z.object({
        arrival: zj.localDate(),
        departure: zj.localDate(),
    }),
    prices: z.object({
        fullTicket: z.number(),
        stageTicket: z.number(),
        silverSponsor: z.number(),
        goldSponsor: z.number(),
        tShirt: z.number(),
    }),
    registration: z.object({
        opensAt: zj.zonedDateTime(),
        opensIn: z.number(),
        closesAt: zj.zonedDateTime(),
        closesIn: z.number(),
    }),
    reservation: z.object({
        closesAt: zj.zonedDateTime(),
        closesIn: z.number(),
    }),
});

const registrationSpecsSelector = createDataSelector(
    createResourceSelector({
        type: "registration_specs",
        attributesSchema: attributesSchema,
        relationships: {
            speciesCategories: {
                relationshipType: "many",
                include: {
                    type: "species_category",
                    attributesSchema: z.object({ name: z.string(), description: z.string() }),
                },
            },
        },
    }),
);

export type RegistrationSpecs = ReturnType<typeof registrationSpecsSelector>;
export type SpeciesCategory = RegistrationSpecs["speciesCategories"][number];

export const useRegistrationSpecsQuery = (): UseQueryResult<RegistrationSpecs> => {
    return useQuery({
        queryKey: ["registration-specs"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/registration/registration-specs"), {
                signal,
                credentials: "include",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        staleTime: 60 * 15 * 1000,
        select: registrationSpecsSelector,
    });
};
