import { useInvoicesQuery } from "@/queries/invoice.ts";
import { LinearProgress, List, Typography } from "@mui/material";
import type { ReactNode } from "react";
import InvoiceListItem from "./InvoiceListItem.tsx";

const InvoicesList = (): ReactNode => {
    const invoicesQuery = useInvoicesQuery();

    if (invoicesQuery.isError) {
        throw invoicesQuery.error;
    }

    if (invoicesQuery.isPending) {
        return <LinearProgress />;
    }

    const invoices = invoicesQuery.data;

    if (invoices.length === 0) {
        return <Typography>Invoices will be available once you completed your payment.</Typography>;
    }

    return (
        <List disablePadding>
            {invoices.map((invoice) => (
                <InvoiceListItem invoice={invoice} key={invoice.id} />
            ))}
        </List>
    );
};

export default InvoicesList;
