import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import { Box, Typography } from "@mui/material";
import { RhfAutocomplete } from "mui-rhf-integration";
import type { ReactNode } from "react";
import type { Control, FieldPathByValue, FieldValues } from "react-hook-form";
import { z } from "zod";

export const speciesCategorySchema = z
    .object({
        id: z.string(),
        name: z.string(),
        description: z.string(),
    })
    .transform((value) => value.id)
    .nullable();

type Props<
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, z.input<typeof speciesCategorySchema>>,
> = {
    control: Control<TFieldValues>;
    name: TName;
};

const DetailsFieldset = <
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, z.input<typeof speciesCategorySchema>>,
>({
    control,
    name,
}: Props<TFieldValues, TName>): ReactNode => {
    const { speciesCategories } = useRegistrationSpecs();

    return (
        <RhfAutocomplete
            textFieldProps={{ label: "Species category" }}
            options={speciesCategories}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderOption={(props, option) => (
                <Box component="li" {...props} key={option.id}>
                    <Box component="div">
                        <Typography>{option.name}</Typography>
                        <Typography color="grey.600" variant="body2">
                            {option.description}
                        </Typography>
                    </Box>
                </Box>
            )}
            control={control}
            name={name}
            freeSolo={false}
        />
    );
};

export default DetailsFieldset;
