import type { ControlledDialogProps } from "@/hooks/useDialogController.ts";
import { usePaymentIntentQuery } from "@/queries/payment-intent.ts";
import {
    Alert,
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";

type Props = {
    dialogProps: ControlledDialogProps;
    paymentIntentId: string;
};

const BankWireTransferDialog = ({ dialogProps, paymentIntentId }: Props): JSX.Element => {
    const paymentIntentQuery = usePaymentIntentQuery(paymentIntentId);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    if (paymentIntentQuery.isError) {
        throw paymentIntentQuery.error;
    }

    if (paymentIntentQuery.isPending) {
        return (
            <Backdrop open>
                <CircularProgress />
            </Backdrop>
        );
    }

    const paymentIntent = paymentIntentQuery.data;

    return (
        <Dialog {...dialogProps} maxWidth="xs" fullWidth fullScreen={fullScreen}>
            <DialogTitle>Bank Transfer</DialogTitle>
            {paymentIntent.status === "requires_action" && paymentIntent.instructionsUrl ? (
                <DialogContent dividers>
                    <Typography paragraph>
                        You have decided to pay via bank wire transfer. In order to complete your
                        payment, please follow the{" "}
                        <Link href={paymentIntent.instructionsUrl} target="_blank" rel="noreferrer">
                            instructions on this page
                        </Link>
                        .
                    </Typography>
                    <Alert severity="info" sx={{ mb: 2 }}>
                        Please note that these instructions are specific to your transaction and
                        must not be used for any other transaction.
                    </Alert>
                    <Typography paragraph>
                        You can always view the instructions again by clicking on the info icon in
                        the payments table.
                    </Typography>
                </DialogContent>
            ) : (
                <DialogContent dividers>
                    <Typography paragraph>There are no further actions required.</Typography>
                </DialogContent>
            )}
            <DialogActions>
                <Button
                    autoFocus
                    onClick={() => {
                        dialogProps.onClose();
                    }}
                    color="primary"
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BankWireTransferDialog;
