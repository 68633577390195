import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import type { RegistrationSpecs } from "@/queries/registration-specs.ts";
import type { RoomReservation } from "@/queries/room-reservation.ts";
import { ChronoUnit } from "@js-joda/core";
import { useMemo } from "react";

type Booking = {
    capacity: number;
    prices: number[];
};

const buildDays = (reservation: RoomReservation, dates: RegistrationSpecs["dates"]) => {
    const mainOccupants = reservation.roomMates.length + 1;
    const days = new Array(dates.arrival.until(dates.departure, ChronoUnit.DAYS)).fill(
        mainOccupants,
    ) as number[];
    const minDelta = Math.min(
        reservation.arrival,
        ...reservation.roomMates.map((roomMate) => roomMate.arrival),
    );
    const maxDelta = Math.max(
        reservation.departure,
        ...reservation.roomMates.map((roomMate) => roomMate.departure),
    );

    for (let timeFrame = minDelta; timeFrame <= maxDelta; ++timeFrame) {
        if (timeFrame === 0) {
            continue;
        }

        let occupancy: number;

        if (timeFrame < 0) {
            occupancy =
                (reservation.arrival <= timeFrame ? 1 : 0) +
                reservation.roomMates.filter((roomMate) => roomMate.arrival <= timeFrame).length;
        } else {
            occupancy =
                (reservation.departure >= timeFrame ? 1 : 0) +
                reservation.roomMates.filter((roomMate) => roomMate.departure >= timeFrame).length;
        }

        days.push(occupancy);
    }

    return days;
};

const useTotalPrice = (reservation: RoomReservation): number => {
    const { dates } = useRegistrationSpecs();

    return useMemo(() => {
        const days = buildDays(reservation, dates);

        const bookings: Booking[] = [reservation.roomType.regular];

        if (reservation.roomType.upgrades) {
            bookings.push(...reservation.roomType.upgrades);
        }

        let totalPrice = 0;

        for (const occupancy of days) {
            let booking: Booking | undefined = undefined;
            let offset = 0;

            for (booking of bookings) {
                if (booking.capacity >= occupancy) {
                    break;
                }

                offset += booking.prices.length;
            }

            if (!booking) {
                throw new Error("No matching booking found");
            }

            totalPrice += booking.prices[occupancy - offset - 1];
        }

        return totalPrice;
    }, [reservation, dates]);
};

export default useTotalPrice;
