import {
    publicOnlyRegistrationAttributesSchema,
    publicOnlyRegistrationRelationships,
} from "@/queries/registration.ts";
import { roomTypeAttributesSchema, roomTypeRelationships } from "@/queries/room-type.ts";
import { apiUrl } from "@/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
    type Relationships,
    createDataSelector,
    createNullableResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const attributesSchema = z.object({
    arrival: z.number().int().nonpositive(),
    departure: z.number().int().nonnegative(),
    upgradedCapacity: z.number().int().positive().nullable(),
});

const relationships = {
    mainContact: {
        relationshipType: "one",
        include: {
            type: "registration",
            attributesSchema: publicOnlyRegistrationAttributesSchema,
            relationships: publicOnlyRegistrationRelationships,
        },
    },
    roomMates: {
        relationshipType: "many",
        include: {
            type: "room_mate",
            attributesSchema: z.object({
                arrival: z.number().int().nonpositive(),
                departure: z.number().int().nonnegative(),
                accepted: z.boolean(),
            }),
            relationships: {
                registration: {
                    relationshipType: "one",
                    include: {
                        type: "registration",
                        attributesSchema: publicOnlyRegistrationAttributesSchema,
                        relationships: publicOnlyRegistrationRelationships,
                    },
                },
            } satisfies Relationships,
        },
    },
    roomType: {
        relationshipType: "one",
        include: {
            type: "room_type",
            attributesSchema: roomTypeAttributesSchema,
            relationships: roomTypeRelationships,
        },
    },
} satisfies Relationships;

const roomReservationSelector = createDataSelector(
    createNullableResourceSelector({
        type: "room_reservation",
        attributesSchema,
        relationships,
    }),
);

export type RoomReservation = NonNullable<ReturnType<typeof roomReservationSelector>>;
export type RoomMate = RoomReservation["roomMates"][number];

export const useRoomReservationQuery = (): UseQueryResult<RoomReservation | null> => {
    return useQuery({
        queryKey: ["registration", "self", "room-reservation"],
        queryFn: async ({ signal }) => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/room-reservation"),
                {
                    signal,
                    credentials: "include",
                    headers: {
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);

            return response.json();
        },
        select: roomReservationSelector,
    });
};
