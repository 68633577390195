import FurtherResourcesCard from "@/pages/HomePage/DashboardPage/FurtherResourcesCard";
import { Grid } from "@mui/material";
import type { ReactNode } from "react";
import BadgeCard from "./BadgeCard/index.ts";
import DetailsCard from "./DetailsCard/index.ts";
import PaymentCard from "./PaymentCard/index.ts";
import ProductsCard from "./ProductsCard/index.ts";
import RoomReservationSection from "./RoomReservationSection/index.ts";

const DashboardPage = (): ReactNode => {
    return (
        <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
                <BadgeCard />
            </Grid>
            <Grid item md={6} xs={12}>
                <PaymentCard />
            </Grid>
            <Grid item md={6} xs={12}>
                <DetailsCard />
            </Grid>
            <Grid item md={6} xs={12}>
                <ProductsCard />
            </Grid>
            <Grid item xs={12}>
                <RoomReservationSection />
            </Grid>
            <Grid item xs={12}>
                <FurtherResourcesCard />
            </Grid>
        </Grid>
    );
};

export default DashboardPage;
