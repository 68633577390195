import type { RoomType } from "@/queries/room-type.ts";
import { formatCents } from "@/utils/format.ts";
import PersonIcon from "@mui/icons-material/Person.js";
import { Box, ListItemText, Stack, useMediaQuery, useTheme } from "@mui/material";
import type { ReactNode } from "react";

export type DisplayableRoomType = Pick<RoomType, "id" | "name"> & {
    regular: Pick<RoomType["regular"], "capacity" | "prices">;
    hotel: Pick<RoomType["hotel"], "name">;
};

type Props = {
    roomType: DisplayableRoomType;
};

const RoomTypeListItemText = ({ roomType }: Props): ReactNode => {
    const lowestPrice = roomType.regular.prices[0];
    const highestPrice = roomType.regular.prices[roomType.regular.prices.length - 1];
    const theme = useTheme();
    const compact = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <ListItemText
            primary={
                <Stack spacing={compact ? 0 : 1} direction={compact ? "column" : "row"}>
                    <Box
                        sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                        {roomType.name}
                    </Box>
                    <div>
                        {Array.from(new Array(roomType.regular.capacity), (_value, index) => (
                            <PersonIcon
                                fontSize="small"
                                // biome-ignore lint/suspicious/noArrayIndexKey: Index is the only possible value
                                key={index}
                            />
                        ))}
                    </div>
                </Stack>
            }
            secondary={
                <>
                    {roomType.hotel.name}, {formatCents(lowestPrice)}
                    {highestPrice !== lowestPrice && (
                        <>
                            {" - "}
                            {formatCents(
                                roomType.regular.prices[roomType.regular.prices.length - 1],
                            )}
                        </>
                    )}
                </>
            }
        />
    );
};

export default RoomTypeListItemText;
