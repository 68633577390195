import { apiUrl } from "@/utils/api.js";
import type { ReactNode } from "react";
import { createContext, useContext, useMemo, useState } from "react";

type CsrfContextValue = {
    csrfToken: Promise<string>;
    setCsrfToken: (csrfToken: string) => void;
};

const CsrfContext = createContext<CsrfContextValue | null>(null);

const loadCsrfToken = async (): Promise<string> => {
    const response = await fetch(apiUrl("/.csrf").toString(), {
        credentials: "include",
    });

    if (!response.ok) {
        throw new Error("Failed to acquire CSRF token");
    }

    const csrfToken = response.headers.get("X-FV-CSRF");

    if (!csrfToken) {
        throw new Error("Response did not include CSRF token");
    }

    return csrfToken;
};

type Props = {
    children: ReactNode;
};

const initialCsrfToken = loadCsrfToken();

const CsrfProvider = ({ children }: Props): JSX.Element => {
    const [csrfToken, setCsrfToken] = useState(initialCsrfToken);

    const value = useMemo(
        () => ({
            csrfToken,
            setCsrfToken: (csrfToken: string) => {
                setCsrfToken(Promise.resolve(csrfToken));
            },
        }),
        [csrfToken],
    );

    return <CsrfContext.Provider value={value}>{children}</CsrfContext.Provider>;
};

export const useCsrf = (): CsrfContextValue => {
    const context = useContext(CsrfContext);

    if (!context) {
        throw new Error("Context used outside provider");
    }

    return context;
};

export default CsrfProvider;
