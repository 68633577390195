import { FormControlLabel, Stack } from "@mui/material";
import { RhfSwitch } from "mui-rhf-integration";
import type { ReactNode } from "react";
import type { Control, FieldPathByValue, FieldValues, Path } from "react-hook-form";
import { z } from "zod";

export const detailsSchema = z.object({
    firstFurryCon: z.boolean().default(false),
    fursuiter: z.boolean().default(false),
});

type Props<
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, z.input<typeof detailsSchema>>,
> = {
    control: Control<TFieldValues>;
    name: TName;
};

const DetailsFieldset = <
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, z.input<typeof detailsSchema>>,
>({
    control,
    name,
}: Props<TFieldValues, TName>): ReactNode => {
    return (
        <Stack>
            <FormControlLabel
                control={
                    <RhfSwitch
                        control={control}
                        name={`${name}.firstFurryCon` as Path<TFieldValues>}
                    />
                }
                label="This is my first furry con"
            />

            <FormControlLabel
                control={
                    <RhfSwitch control={control} name={`${name}.fursuiter` as Path<TFieldValues>} />
                }
                label="I'm a fursuiter"
            />
        </Stack>
    );
};

export default DetailsFieldset;
