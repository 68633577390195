import { Card, CardContent, CardHeader } from "@mui/material";
import type { ReactNode } from "react";
import PaymentsList from "./PaymentsList.tsx";

const PaymentsCard = (): ReactNode => {
    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardHeader title="Payments" />
            <CardContent>
                <PaymentsList />
            </CardContent>
        </Card>
    );
};

export default PaymentsCard;
