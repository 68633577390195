import useApiFetch from "@/hooks/useApiFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

type CreateWaitingSpotValues = {
    roomTypeId: string;
};

const createRelationships = (roomTypeId: string) => ({
    roomType: {
        data: {
            id: roomTypeId,
            type: "room_type",
        },
    },
});

export const useCreateWaitingSpotMutation = (): UseMutationResult<
    void,
    Error,
    CreateWaitingSpotValues
> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl("/registration/registrations/self/waiting-spots"), {
                method: "POST",
                body: JSON.stringify({
                    data: {
                        type: "waiting_spot",
                        relationships: createRelationships(values.roomTypeId),
                    },
                }),
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self", "waiting-spots"],
            });
        },
    });
};

type DeleteWaitingSpotValues = {
    id: string;
};

export const useDeleteWaitingSpotMutation = (): UseMutationResult<
    void,
    Error,
    DeleteWaitingSpotValues
> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/registration/registrations/self/waiting-spots/${values.id}`),
                {
                    method: "DELETE",
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ["registration", "self", "waiting-spots"],
            });
        },
    });
};
