import ConfirmDialog from "@/components/ConfirmDialog/index.ts";
import { useEnsuredRegistration } from "@/components/RegistrationProvider/index.ts";
import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import useDialogController from "@/hooks/useDialogController.ts";
import {
    useDeleteRoomReservationMutation,
    useLeaveRoomMutation,
    useUpdateRoomReservationMutation,
} from "@/mutations/room-reservation.ts";
import type { RoomReservation } from "@/queries/room-reservation.ts";
import type { RoomType } from "@/queries/room-type.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { formatCents } from "@/utils/format.ts";
import BedIcon from "@mui/icons-material/Bed";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Grid,
    Stack,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useConfirm } from "react-confirm-hook";
import RoomTypeButton from "../../RoomTypeButton.tsx";
import OccupantsCard, { TimeFrame } from "./OccupantsCard/index.ts";
import RoomMateSearchDialog from "./RoomMateSearchDialog.tsx";
import UpgradeButton from "./UpgradeButton.tsx";
import useTotalPrice from "./useTotalPrice.ts";

type Props = {
    reservation: RoomReservation;
};

const ActiveReservationCard = ({ reservation }: Props): ReactNode => {
    const {
        reservation: { state },
    } = useRegistrationSpecs();
    const totalPrice = useTotalPrice(reservation);
    const registration = useEnsuredRegistration();
    const updateMutation = useUpdateRoomReservationMutation();
    const deleteMutation = useDeleteRoomReservationMutation();
    const leaveMutation = useLeaveRoomMutation();
    const confirm = useConfirm(ConfirmDialog);
    const { enqueueSnackbar } = useSnackbar();
    const searchDialogController = useDialogController();

    const isMainContact = reservation.mainContact.id === registration.id;

    const handleRoomTypeChange = (roomType: RoomType) => {
        confirm({
            title: "Confirm Room Type Change",
            message: (
                <>
                    You are about to change your room type to {roomType.name} ({roomType.hotel.name}
                    ). Your current room will be assigned to the next person on the waiting list. Do
                    you want to continue?
                </>
            ),
            onConfirm: async () => {
                try {
                    await updateMutation.mutateAsync({
                        roomTypeId: roomType.id,
                    });
                    enqueueSnackbar("Your room type has been changed", { variant: "success" });
                } catch (error) {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                }
            },
        });
    };

    const handleLeave = () => {
        confirm({
            title: "Confirm Leave",
            message: "You are about to leave this room. Do you want to continue?",
            onConfirm: async () => {
                try {
                    await leaveMutation.mutateAsync();
                    enqueueSnackbar("You have left the room", { variant: "success" });
                } catch (error) {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                }
            },
        });
    };

    const handleDelete = () => {
        confirm({
            title: "Confirm Cancellation",
            message: (
                <>
                    You are about to cancel your reservation. If you have room mates, they will also
                    lose their reservation. To prevent this, transfer room ownership to a room mate
                    and then leave the room.
                    <br />
                    <br />
                    If you are not able to attend this year and have already paid for your
                    convention ticket, consider selling your registration together with your room
                    reservation.
                </>
            ),
            onConfirm: async () => {
                try {
                    await deleteMutation.mutateAsync();
                    enqueueSnackbar("You have cancelled your reservation", { variant: "success" });
                } catch (error) {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                }
            },
        });
    };

    return (
        <Card>
            <CardHeader avatar={<BedIcon />} title="Room Reservation" />
            <CardContent>
                <Typography paragraph>
                    You have a reservation in {reservation.roomType.hotel.name} for{" "}
                    {reservation.roomType.name}.
                </Typography>
                <Typography paragraph>
                    The total price of the room is {formatCents(totalPrice)}. The room has to be
                    paid on checkout at the hotel reception.
                </Typography>

                <Grid container spacing={2} alignItems="stretch">
                    <Grid item lg={3} md={6} xs={12}>
                        <OccupantsCard reservation={reservation} timeFrame={TimeFrame.SuperEarly} />
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                        <OccupantsCard reservation={reservation} timeFrame={TimeFrame.Early} />
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                        <OccupantsCard reservation={reservation} timeFrame={TimeFrame.Main} />
                    </Grid>
                    <Grid item lg={3} md={6} xs={12}>
                        <OccupantsCard reservation={reservation} timeFrame={TimeFrame.Late} />
                    </Grid>
                </Grid>
            </CardContent>
            {state === "open" && (
                <CardActions disableSpacing>
                    {isMainContact ? (
                        <Stack
                            spacing={1}
                            direction={{ xs: "column", md: "row" }}
                            sx={{ width: "100%" }}
                        >
                            <RoomTypeButton
                                label="Change Room Type"
                                emptyLabel="Booked Out"
                                onSelect={handleRoomTypeChange}
                                loading={updateMutation.isPending}
                                excludedRoomTypes={[reservation.roomType]}
                                requiredCapacity={reservation.roomMates.length + 1}
                                onlyDirectBooking
                                excludeUnavailable
                            />
                            <UpgradeButton reservation={reservation} />
                            <Button
                                onClick={() => {
                                    searchDialogController.open();
                                }}
                            >
                                Room Mate Search
                            </Button>
                            <Button onClick={handleDelete} color="error">
                                Cancel Reservation
                            </Button>

                            {searchDialogController.mount && (
                                <RoomMateSearchDialog
                                    dialogProps={searchDialogController.dialogProps}
                                />
                            )}
                        </Stack>
                    ) : (
                        <Button onClick={handleLeave}>Leave room</Button>
                    )}
                </CardActions>
            )}
        </Card>
    );
};

export default ActiveReservationCard;
