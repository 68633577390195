import { useEnsuredRegistration } from "@/components/RegistrationProvider/index.ts";
import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import UserAvatar from "@/components/UserAvatar/index.js";
import type { RegistrationSpecs } from "@/queries/registration-specs.ts";
import type { RoomReservation } from "@/queries/room-reservation.ts";
import { DateTimeFormatter } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";
import {
    Box,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListSubheader,
    Paper,
} from "@mui/material";
import { type ReactNode, useMemo } from "react";
import FreeBedListItem from "./FreeBedListItem.tsx";
import RoomMateListItem from "./RoomMateListItem.tsx";
import StayDurationToggle from "./StayDurationToggle.tsx";
import { TimeFrame, compareRoomMates, hasStay } from "./utils.ts";

type Props = {
    reservation: RoomReservation;
    timeFrame: TimeFrame;
};

const dateFormatter = DateTimeFormatter.ofPattern("d MMM").withLocale(Locale.US);

const getLabel = (dates: RegistrationSpecs["dates"], timeFrame: TimeFrame): string => {
    switch (timeFrame) {
        case TimeFrame.SuperEarly:
            return `Super Early Arrival (${dates.arrival.minusDays(2).format(dateFormatter)})`;

        case TimeFrame.Early:
            return `Early Arrival (${dates.arrival.minusDays(1).format(dateFormatter)})`;

        case TimeFrame.Main:
            return `
                Main Days (${dates.arrival.format(dateFormatter)}
                -
                ${dates.departure.format(dateFormatter)})
            `;

        case TimeFrame.Late:
            return `Late Departure (${dates.departure.plusDays(1).format(dateFormatter)})`;
    }
};

const Occupants = ({ reservation, timeFrame }: Props): ReactNode => {
    const {
        dates,
        reservation: { state },
    } = useRegistrationSpecs();
    const registration = useEnsuredRegistration();

    const mainContactIdentity = reservation.mainContact.identity as NonNullable<
        RoomReservation["mainContact"]["identity"]
    >;
    const showMainContact =
        timeFrame === 0 ||
        (timeFrame < 0 && reservation.arrival <= timeFrame) ||
        (timeFrame > 0 && reservation.departure >= timeFrame);
    const capacity = reservation.upgradedCapacity ?? reservation.roomType.regular.capacity;
    const isMainContact = reservation.mainContact.id === registration.id;
    const selfRoomMate = reservation.roomMates.find(
        (roomMate) => roomMate.registration.id === registration.id,
    );
    const selfTimeFrame = selfRoomMate ?? reservation;
    const selfHasStay = hasStay(selfTimeFrame, timeFrame);

    const label = useMemo(() => getLabel(dates, timeFrame), [dates, timeFrame]);

    return (
        <Paper variant="outlined" sx={{ height: "100%" }}>
            <List>
                <ListSubheader sx={{ display: "flex" }}>
                    <Box sx={{ flexGrow: 1 }}>{label}</Box>
                    {timeFrame !== TimeFrame.Main && state === "open" && (
                        <StayDurationToggle timeFrame={timeFrame} hasStay={selfHasStay} />
                    )}
                </ListSubheader>
                {showMainContact && (
                    <ListItem>
                        <ListItemAvatar>
                            <UserAvatar identity={mainContactIdentity} />
                        </ListItemAvatar>
                        <ListItemText
                            primary={mainContactIdentity.nickname}
                            primaryTypographyProps={{
                                sx: {
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                },
                            }}
                        />
                    </ListItem>
                )}
                {reservation.roomMates
                    .sort(compareRoomMates)
                    .filter((roomMate) => hasStay(roomMate, timeFrame))
                    .map((roomMate) => (
                        <RoomMateListItem
                            key={roomMate.id}
                            roomMate={roomMate}
                            actionsAllowed={
                                isMainContact && timeFrame === TimeFrame.Main && state === "open"
                            }
                        />
                    ))}
                {!selfHasStay && (
                    <ListItem>
                        <ListItemText primary="You haven't booked this" />
                    </ListItem>
                )}
                {timeFrame === TimeFrame.Main &&
                    Array.from(
                        { length: capacity - 1 - reservation.roomMates.length },
                        (_value, index) => (
                            <FreeBedListItem
                                // biome-ignore lint/suspicious/noArrayIndexKey: There's nothing but an index
                                key={index}
                                inviteAllowed={isMainContact && state === "open"}
                            />
                        ),
                    )}
            </List>
        </Paper>
    );
};

export default Occupants;
