import { roomTypeAttributesSchema, roomTypeRelationships } from "@/queries/room-type.ts";
import { apiUrl } from "@/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const attributesSchema = z.object({
    position: z.number().int(),
    unpaidLeft: z.number().int(),
});

const waitingSpotsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "waiting_spot",
        attributesSchema,
        relationships: {
            roomType: {
                relationshipType: "one",
                include: {
                    type: "room_type",
                    attributesSchema: roomTypeAttributesSchema,
                    relationships: roomTypeRelationships,
                },
            },
        },
    }),
);

export type WaitingSpot = ReturnType<typeof waitingSpotsSelector>[number];

export const useWaitingSpotsQuery = (): UseQueryResult<WaitingSpot[]> => {
    return useQuery({
        queryKey: ["registration", "self", "waiting-spots"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/registration/registrations/self/waiting-spots"), {
                signal,
                credentials: "include",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: waitingSpotsSelector,
    });
};
