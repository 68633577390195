import { Box, type SxProps } from "@mui/material";
import type { ReactNode } from "react";

const handleIntersection = (
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver,
) => {
    for (const entry of entries) {
        if (!entry.isIntersecting) {
            continue;
        }

        entry.target.classList.add(`fi-${(entry.target as HTMLSpanElement).dataset.countrycode}`);
        observer.unobserve(entry.target);
    }
};

const observer = new IntersectionObserver(handleIntersection);

type Props = {
    countryCode: string;
    sx?: SxProps;
};

const FlagIcon = ({ countryCode, sx }: Props): ReactNode => {
    return (
        <Box
            component="span"
            sx={sx}
            ref={(ref) => {
                if (ref) {
                    observer.observe(ref as HTMLSpanElement);
                }
            }}
            data-countrycode={countryCode.toLowerCase()}
            className="fi"
        />
    );
};

export default FlagIcon;
