import { usePaymentsQuery } from "@/queries/payment";
import { LinearProgress, List, Typography } from "@mui/material";
import type { ReactNode } from "react";
import PaymentListItem from "./PaymentListItem.tsx";

const PaymentsList = (): ReactNode => {
    const paymentsQuery = usePaymentsQuery();

    if (paymentsQuery.isError) {
        throw paymentsQuery.error;
    }

    if (paymentsQuery.isPending) {
        return <LinearProgress />;
    }

    const payments = paymentsQuery.data;

    if (payments.length === 0) {
        return <Typography>You haven't initiated a payment yet.</Typography>;
    }

    return (
        <List disablePadding>
            {payments.map((payment) => (
                <PaymentListItem payment={payment} key={payment.id} />
            ))}
        </List>
    );
};

export default PaymentsList;
