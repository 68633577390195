import type { ControlledDialogProps } from "@/hooks/useDialogController.ts";
import { useInviteRoomMateMutation } from "@/mutations/room-reservation";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    registrationNumber: z
        .string()
        .regex(/^\d{1,4}$/, "Invalid number")
        .transform((value) => Number.parseInt(value, 10)),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
};

const InviteFormDialog = ({ dialogProps }: Props) => {
    const { enqueueSnackbar } = useSnackbar();
    const inviteMutation = useInviteRoomMateMutation();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });

    const handleSubmit = (values: TransformedValues) => {
        inviteMutation.mutate(
            {
                attributes: values,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar("Room Mate has been invited", { variant: "success" });
                    dialogProps.onClose();
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <Dialog
            {...dialogProps}
            PaperProps={{
                component: "form",
                onSubmit: form.handleSubmit(handleSubmit),
                noValidate: true,
            }}
            maxWidth="xs"
            fullWidth
            fullScreen={fullScreen}
        >
            <DialogTitle>Invite Room Mate</DialogTitle>
            <DialogContent dividers>
                <DialogContentText sx={{ mb: 2 }}>
                    Invite another person into your room. They must already be registered and not
                    have another confirmed room reservation. After the invitation, they have to join
                    your room with your registration number.
                </DialogContentText>
                <RhfTextField
                    control={form.control}
                    name="registrationNumber"
                    label="Registration Number"
                    required
                    fullWidth
                    inputProps={{
                        maxLength: 4,
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={dialogProps.onClose}
                    disabled={inviteMutation.isPending}
                    color="inherit"
                >
                    Cancel
                </Button>
                <LoadingButton loading={inviteMutation.isPending} type="submit" color="primary">
                    Invite
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default InviteFormDialog;
