import { useEnsuredRegistration } from "@/components/RegistrationProvider/index.ts";
import useDialogController from "@/hooks/useDialogController.ts";
import { Alert, Backdrop, CircularProgress, Grid, Link, Stack, Typography } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useCallback, useState } from "react";
import BankTransferDialog from "./BankTransferDialog.tsx";
import InvoicesCard from "./InvoicesCard/index.ts";
import PaymentTabs from "./PaymentTabs/index.ts";
import PaymentsCard from "./PaymentsCard/index.ts";
import usePaymentIntentPolling from "./usePaymentIntentPolling.ts";
import type { Finisher } from "./utils.ts";

const PaymentPage = (): ReactNode => {
    const registration = useEnsuredRegistration();
    const [processing, setProcessing] = useState(false);
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();
    const bankTransferDialogController = useDialogController();
    const [bankTransferPaymentIntentId, setBankTransferPaymentIntentId] = useState<string | null>(
        null,
    );

    const handleInit = useCallback(() => {
        setProcessing(true);
    }, []);

    const handleFinish = useCallback<Finisher>(
        async (result) => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ["registration", "self"] }),
                queryClient.invalidateQueries({ queryKey: ["payments"] }),
            ]);

            setProcessing(false);

            switch (result.type) {
                case "failure": {
                    enqueueSnackbar(result.message, { variant: "error" });
                    return;
                }

                case "payment_intent_status": {
                    switch (result.status) {
                        case "succeeded": {
                            enqueueSnackbar("Your payment was successful and is now confirmed", {
                                variant: "success",
                            });
                            return;
                        }

                        case "processing": {
                            enqueueSnackbar(
                                "Your payment was successful and is now being processed",
                                { variant: "success" },
                            );
                            return;
                        }

                        default: {
                            enqueueSnackbar("Your payment could not be processed", {
                                variant: "error",
                            });
                            return;
                        }
                    }
                }

                case "payment_intent_id": {
                    bankTransferDialogController.open();
                    setBankTransferPaymentIntentId(result.id);
                    return;
                }
            }
        },
        [queryClient, enqueueSnackbar, bankTransferDialogController.open],
    );

    usePaymentIntentPolling(handleInit, handleFinish);

    const { remainingAmount } = registration.paymentSummary;

    return (
        <>
            <Stack spacing={2}>
                <div>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <PaymentsCard />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InvoicesCard />
                        </Grid>
                    </Grid>
                </div>

                <Alert severity="info">
                    Please note that payments are <strong>non-refundable</strong>. For further
                    information check our{" "}
                    <Link
                        href="https://furvester.org/attending/rules-of-conduct"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Rules of Conduct
                    </Link>
                    .
                </Alert>

                {(remainingAmount > 0 || processing) && (
                    <PaymentTabs
                        remainingAmount={remainingAmount}
                        onInit={handleInit}
                        onFinish={handleFinish}
                    />
                )}
            </Stack>

            {bankTransferDialogController.mount && bankTransferPaymentIntentId && (
                <BankTransferDialog
                    dialogProps={bankTransferDialogController.dialogProps}
                    paymentIntentId={bankTransferPaymentIntentId}
                />
            )}

            <Backdrop open={processing}>
                <Stack spacing={2} alignItems="center">
                    <CircularProgress />
                    <Typography>Processing payment</Typography>
                </Stack>
            </Backdrop>
        </>
    );
};

export default PaymentPage;
