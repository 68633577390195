import { apiUrl } from "@/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
    type Relationships,
    createDataSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

export const roomTypeAttributesSchema = z.object({
    name: z.string(),
    regular: z.object({
        prices: z.array(z.number().int()),
        capacity: z.number().int(),
        available: z.number().int().optional(),
    }),
    upgrades: z
        .array(
            z.object({
                prices: z.array(z.number().int()),
                capacity: z.number().int(),
                available: z.number().int().optional(),
            }),
        )
        .optional(),
    directBookingDisabled: z.boolean().optional(),
});

export const roomTypeRelationships = {
    hotel: {
        relationshipType: "one",
        include: {
            type: "hotel",
            attributesSchema: z.object({
                name: z.string(),
            }),
        },
    },
} satisfies Relationships;

const roomTypesSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "room_type",
        attributesSchema: roomTypeAttributesSchema,
        relationships: roomTypeRelationships,
    }),
);

export type RoomType = ReturnType<typeof roomTypesSelector>[number];

export const useRoomTypesQuery = (): UseQueryResult<RoomType[]> => {
    return useQuery({
        queryKey: ["room-types"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/registration/room-types"), {
                signal,
                credentials: "include",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: roomTypesSelector,
    });
};
