import useDialogController from "@/hooks/useDialogController.ts";
import { Button, type SxProps } from "@mui/material";
import type { ReactNode } from "react";
import JoinFormDialog from "./JoinFormDialog.tsx";

type Props = {
    sx?: SxProps;
};

const JoinButton = ({ sx }: Props): ReactNode => {
    const dialogController = useDialogController();

    return (
        <>
            <Button
                onClick={() => {
                    dialogController.open();
                }}
                sx={sx}
            >
                Join Room
            </Button>

            <JoinFormDialog dialogProps={dialogController.dialogProps} />
        </>
    );
};

export default JoinButton;
