import { apiUrl } from "@/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    createResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const attributesSchema = z.object({
    date: zj.localDate(),
    invoiceNumber: z.string(),
    amount: z.number().int(),
});

const invoicesSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "invoice",
        attributesSchema,
    }),
);

export const invoiceUrlSelector = createDataSelector(
    createResourceSelector({
        type: "invoice_url",
        attributesSchema: z.object({
            url: z.string(),
            expiresIn: z.number(),
        }),
    }),
);

export type Invoice = ReturnType<typeof invoicesSelector>[number];

export const useInvoicesQuery = (): UseQueryResult<Invoice[]> => {
    return useQuery({
        queryKey: ["registration", "self", "invoices"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/registration/registrations/self/invoices"), {
                signal,
                credentials: "include",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: invoicesSelector,
    });
};
