import { useToggleStayDurationMutation } from "@/mutations/room-reservation.ts";
import { getErrorMessage } from "@/utils/api.ts";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { type ReactNode, useCallback } from "react";

type Props = {
    timeFrame: number;
    hasStay: boolean;
};

const StayDurationToggle = ({ timeFrame, hasStay }: Props): ReactNode => {
    const toggleStayDurationMutation = useToggleStayDurationMutation();
    const { enqueueSnackbar } = useSnackbar();

    const toggleStayDuration = useCallback(async () => {
        let key: "arrival" | "departure";
        let value: number;

        if (timeFrame < 0) {
            key = "arrival";
            value = hasStay ? timeFrame + 1 : timeFrame;
        } else {
            key = "departure";
            value = hasStay ? timeFrame - 1 : timeFrame;
        }

        toggleStayDurationMutation.mutate(
            {
                attributes: { [key]: value },
            },
            {
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    }, [toggleStayDurationMutation, timeFrame, hasStay, enqueueSnackbar]);

    return (
        <Tooltip title="Change Stay Duration">
            <IconButton
                onClick={() => {
                    void toggleStayDuration();
                }}
                size="large"
                edge="end"
                disabled={toggleStayDurationMutation.isPending}
            >
                {toggleStayDurationMutation.isPending ? (
                    <CircularProgress size={24} />
                ) : hasStay ? (
                    <RemoveIcon />
                ) : (
                    <AddIcon />
                )}
            </IconButton>
        </Tooltip>
    );
};

export default StayDurationToggle;
