import CsrfProvider from "@/components/CsrfProvider/index.js";
import MultiProvider from "@/components/MultiProvider/index.ts";
import RegistrationProvider from "@/components/RegistrationProvider/index.ts";
import RegistrationSpecsProvider from "@/components/RegistrationSpecsProvider/index.ts";
import SessionProvider from "@/components/SessionProvider/index.ts";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "flag-icons/css/flag-icons.min.css";
import { SnackbarProvider } from "notistack";
import { StrictMode } from "react";
import { ConfirmProvider } from "react-confirm-hook";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { router } from "./router.js";

const container = document.getElementById("root");

if (!container) {
    throw new Error("Root container missing");
}

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: "#d35015",
        },
    },
});

const queryClient = new QueryClient();

const root = createRoot(container);

root.render(
    <StrictMode>
        <MultiProvider
            providerCreators={[
                (children) => (
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        {children}
                    </ThemeProvider>
                ),
                (children) => (
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        {children}
                    </LocalizationProvider>
                ),
                (children) => <CsrfProvider>{children}</CsrfProvider>,
                (children) => (
                    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
                ),
                (children) => <SnackbarProvider maxSnack={1}>{children}</SnackbarProvider>,
                (children) => (
                    <SessionProvider identityUiBaseUrl={import.meta.env.VITE_APP_IDENTITY_URL}>
                        {children}
                    </SessionProvider>
                ),
                (children) => <RegistrationSpecsProvider>{children}</RegistrationSpecsProvider>,
                (children) => <RegistrationProvider>{children}</RegistrationProvider>,
                (children) => <ConfirmProvider>{children}</ConfirmProvider>,
            ]}
        >
            <RouterProvider router={router} />
        </MultiProvider>
    </StrictMode>,
);
