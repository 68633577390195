import { useEnsuredRegistration } from "@/components/RegistrationProvider/index.ts";
import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import { useProductsQuery } from "@/queries/product.ts";
import { formatCents } from "@/utils/format.ts";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Card, CardActions, CardContent, CardHeader, LinearProgress, List } from "@mui/material";
import type { ReactNode } from "react";
import AddAction from "./AddAction.tsx";
import ProductListItem from "./ProductListItem.tsx";

const ProductsCard = (): ReactNode => {
    const {
        registration: { state },
    } = useRegistrationSpecs();
    const productsQuery = useProductsQuery();
    const { paymentSummary } = useEnsuredRegistration();

    if (productsQuery.isError) {
        throw productsQuery.error;
    }

    let cardContent: ReactNode;

    if (productsQuery.isPending) {
        cardContent = <LinearProgress />;
    } else {
        const hasGoldSponsorUpgrade = productsQuery.data.some(
            (product) => product.type === "gold_sponsor_upgrade",
        );

        cardContent = (
            <List disablePadding>
                {productsQuery.data.map((product) => (
                    <ProductListItem
                        product={product}
                        state={state}
                        hasGoldSponsorUpgrade={hasGoldSponsorUpgrade}
                        removableAmount={paymentSummary.remainingAmount}
                        key={product.id}
                    />
                ))}
            </List>
        );
    }

    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardHeader
                avatar={<ShoppingCartIcon />}
                title="Products"
                subheader={formatCents(paymentSummary.totalAmount)}
            />
            <CardContent>{cardContent}</CardContent>
            {productsQuery.data && (
                <CardActions sx={{ mt: "auto" }}>
                    <AddAction products={productsQuery.data} disabled={state === "over"} />
                </CardActions>
            )}
        </Card>
    );
};

export default ProductsCard;
