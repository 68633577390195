import useApiFetch from "@/hooks/useApiFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

type ReplaceReservationValues = {
    attributes: {
        content: string;
    };
};

export const useReplaceRoomMateSearchMutation = (): UseMutationResult<
    void,
    Error,
    ReplaceReservationValues
> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/room-reservation/room-mate-search"),
                {
                    method: "PUT",
                    body: JSON.stringify({
                        data: {
                            type: "room_mate_search",
                            ...values,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: ["registration", "self", "room-reservation", "room-mate-search"],
                }),
            ]);
        },
    });
};

export const useDeleteRoomMateSearchMutation = (): UseMutationResult<void, Error, void> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async () => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/room-reservation/room-mate-search"),
                {
                    method: "DELETE",
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({
                    queryKey: ["registration", "self", "room-reservation", "room-mate-search"],
                }),
            ]);
        },
    });
};
