import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import useDialogController from "@/hooks/useDialogController.ts";
import { type CreateProductValues, useCreateProductMutation } from "@/mutations/product.ts";
import type { Product } from "@/queries/product.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { formatCents } from "@/utils/format.ts";
import { Button, ListItemText, Menu, MenuItem, Typography } from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useSnackbar } from "notistack";
import { type ReactNode, useState } from "react";
import TShirtSizeDialog from "./TShirtSizeDialog.tsx";

type Props = {
    products: Product[];
    disabled?: boolean;
};

const AddAction = ({ products, disabled }: Props): ReactNode => {
    const menuState = usePopupState({ variant: "popover", popupId: "add-product-popup" });
    const { prices } = useRegistrationSpecs();
    const createMutation = useCreateProductMutation();
    const { enqueueSnackbar } = useSnackbar();
    const [addProduct, setAddProduct] = useState<"gold_sponsor_upgrade" | "t_shirt" | null>(null);
    const createDialogController = useDialogController();

    const silverSponsorUpgradeAmount =
        products.find((product) => product.type === "silver_sponsor_upgrade")?.amount ?? null;
    const paidTShirtProduct = products.find(
        (product): product is Product & { type: "t_shirt" } =>
            product.type === "t_shirt" && product.amount > 0,
    );
    const hasGoldSponsorUpgrade = products.some(
        (product) => product.type === "gold_sponsor_upgrade",
    );
    const hasFreeTShirt = products.some(
        (product) => product.type === "t_shirt" && product.amount === 0,
    );

    const runMutation = (values: CreateProductValues) => {
        createMutation.mutate(values, {
            onSuccess: () => {
                createDialogController.dialogProps.onClose();
            },
            onError: (error) => {
                enqueueSnackbar(getErrorMessage(error), { variant: "error" });
            },
        });
    };

    return (
        <>
            <Button disabled={disabled || createMutation.isPending} {...bindTrigger(menuState)}>
                Add another product
            </Button>
            <Menu {...bindMenu(menuState)}>
                {!(silverSponsorUpgradeAmount !== null || hasGoldSponsorUpgrade) && (
                    <MenuItem
                        onClick={() => {
                            menuState.close();
                            runMutation({
                                attributes: {
                                    type: "silver_sponsor",
                                },
                            });
                        }}
                    >
                        <ListItemText
                            primary="Silver Sponsor upgrade"
                            secondary={formatCents(prices.silverSponsor)}
                        />
                    </MenuItem>
                )}
                {!hasGoldSponsorUpgrade && (
                    <MenuItem
                        onClick={() => {
                            menuState.close();

                            if (paidTShirtProduct) {
                                runMutation({
                                    attributes: {
                                        type: "gold_sponsor",
                                        tShirtSize: paidTShirtProduct.configuration.size,
                                    },
                                });
                                return;
                            }

                            setAddProduct("gold_sponsor_upgrade");
                            createDialogController.open();
                        }}
                    >
                        <ListItemText
                            primary="Gold Sponsor upgrade"
                            secondary={formatCents(
                                prices.goldSponsor -
                                    (silverSponsorUpgradeAmount ?? 0) -
                                    (paidTShirtProduct?.amount ?? 0),
                            )}
                        />
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        menuState.close();
                        setAddProduct("t_shirt");
                        createDialogController.open();
                    }}
                >
                    <ListItemText
                        primary="T-shirt"
                        secondary={formatCents(
                            hasGoldSponsorUpgrade && hasFreeTShirt === null ? 0 : prices.tShirt,
                        )}
                    />
                </MenuItem>
            </Menu>

            {createDialogController.mount && (
                <>
                    {addProduct === "gold_sponsor_upgrade" && (
                        <TShirtSizeDialog
                            dialogProps={createDialogController.dialogProps}
                            title="Add Gold Sponsor Upgrade"
                            description={
                                <Typography mb={2}>
                                    Your gold sponsor upgrade includes a free T-shirt. Please select
                                    the desired size.
                                </Typography>
                            }
                            submitButtonLabel="Add product"
                            onSubmit={(values) => {
                                runMutation({
                                    attributes: {
                                        type: "gold_sponsor",
                                        tShirtSize: values.tShirtSize,
                                    },
                                });
                            }}
                            isPending={createMutation.isPending}
                        />
                    )}

                    {addProduct === "t_shirt" && (
                        <TShirtSizeDialog
                            dialogProps={createDialogController.dialogProps}
                            title="Add T-shirt"
                            submitButtonLabel="Add product"
                            onSubmit={(values) => {
                                runMutation({
                                    attributes: {
                                        type: "t_shirt",
                                        tShirtSize: values.tShirtSize,
                                    },
                                });
                            }}
                            isPending={createMutation.isPending}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default AddAction;
