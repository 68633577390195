import type { WaitingSpot } from "@/queries/waiting-spot.ts";
import { List } from "@mui/material";
import type { ReactNode } from "react";
import WaitingSpotListItem from "./WaitingSpotListItem.tsx";

type Props = {
    waitingSpots: WaitingSpot[];
};

const WaitingSpotList = ({ waitingSpots }: Props): ReactNode => {
    return (
        <List disablePadding>
            {waitingSpots.map((waitingSpot) => (
                <WaitingSpotListItem waitingSpot={waitingSpot} key={waitingSpot.id} />
            ))}
        </List>
    );
};

export default WaitingSpotList;
