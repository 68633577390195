import { CountrySelect } from "@/components/Registration/index.ts";
import { useEnsuredRegistration } from "@/components/RegistrationProvider/index.ts";
import type { ControlledDialogProps } from "@/hooks/useDialogController.ts";
import { useUpdateRegistrationMutation } from "@/mutations/registration.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Link,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    flagCodes: z.array(z.string()).min(1).max(3),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
};

const EditBadgeDialog = ({ dialogProps }: Props): ReactNode => {
    const registration = useEnsuredRegistration();
    const updateMutation = useUpdateRegistrationMutation();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema, {
            errorMap: (error, context) => {
                if (error.code === "too_small") {
                    return { message: "You must select at least one flag" };
                }

                if (error.code === "too_big") {
                    return { message: "You can only select up to three flag" };
                }

                return { message: context.defaultError };
            },
        }),
        defaultValues: {
            flagCodes: registration.flagCodes,
        },
    });

    const handleSubmit = (values: TransformedValues) => {
        updateMutation.mutate(
            { attributes: { ...values } },
            {
                onSuccess: () => {
                    enqueueSnackbar("Your badge has been updated", { variant: "success" });
                    dialogProps.onClose();
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <Dialog
            {...dialogProps}
            PaperProps={{
                component: "form",
                onSubmit: form.handleSubmit(handleSubmit),
                noValidate: true,
            }}
            maxWidth="xs"
            fullWidth
            fullScreen={fullScreen}
        >
            <DialogTitle>Customize Badge</DialogTitle>
            <DialogContent dividers>
                <Typography mb={2}>
                    You can change your nickname and profile picture on your{" "}
                    <Link
                        href="https://identity.furvester.org/settings"
                        target="_blank"
                        rel="noreferrer"
                    >
                        identity profile
                    </Link>
                    .
                </Typography>

                <CountrySelect
                    control={form.control}
                    name="flagCodes"
                    multiple
                    textFieldProps={{
                        label: "Flags",
                        helperText: "Select up to three flags",
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={dialogProps.onClose}
                    disabled={updateMutation.isPending}
                    color="inherit"
                >
                    Cancel
                </Button>
                <LoadingButton loading={updateMutation.isPending} type="submit" color="primary">
                    Update
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default EditBadgeDialog;
