import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import useDialogController from "@/hooks/useDialogController.ts";
import { useCreateRoomReservationMutation } from "@/mutations/room-reservation.ts";
import { useCreateWaitingSpotMutation } from "@/mutations/waiting-spot.ts";
import { type RoomType, useRoomTypesQuery } from "@/queries/room-type.ts";
import type { WaitingSpot } from "@/queries/waiting-spot.ts";
import { getErrorMessage } from "@/utils/api.ts";
import BedIcon from "@mui/icons-material/Bed";
import {
    Alert,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    LinearProgress,
    Stack,
    Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import JoinButton from "../../JoinButton/index.ts";
import RoomTypeButton from "../../RoomTypeButton.tsx";
import RoomMateSearchesDialog from "./RoomMateSearchesDialog/index.ts";
import WaitingSpotList from "./WaitingSpotList.tsx";

type Props = {
    waitingSpots: WaitingSpot[];
};

const NewReservationCard = ({ waitingSpots }: Props): ReactNode => {
    const {
        reservation: { state },
    } = useRegistrationSpecs();
    const roomTypesQuery = useRoomTypesQuery();
    const createReservationMutation = useCreateRoomReservationMutation();
    const createWaitingSpotMutation = useCreateWaitingSpotMutation();
    const { enqueueSnackbar } = useSnackbar();
    const searchesDialogController = useDialogController();

    if (roomTypesQuery.isError) {
        throw roomTypesQuery.error;
    }

    if (roomTypesQuery.isPending) {
        return <LinearProgress />;
    }

    const handleReservationCreation = (roomType: RoomType) => {
        createReservationMutation.mutate(
            {
                roomTypeId: roomType.id,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar("Room reservation has been created", { variant: "success" });
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    const handleWaitingSpotCreation = (roomType: RoomType) => {
        createWaitingSpotMutation.mutate(
            {
                roomTypeId: roomType.id,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar("You've been added to the waiting list", {
                        variant: "success",
                    });
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <Card>
            <CardHeader avatar={<BedIcon />} title="Room Reservation" />
            <CardContent>
                {state === "open" ? (
                    <>
                        <Typography>
                            You have no room reservation. You can either create a new reservation,
                            join another attendee's room or join the waiting list.
                        </Typography>

                        {waitingSpots.length > 0 && (
                            <>
                                <Divider sx={{ my: 2 }} />
                                <Typography sx={{ mb: 1 }}>
                                    You are currently on the waiting list for the following room
                                    types:
                                </Typography>

                                <WaitingSpotList waitingSpots={waitingSpots} />

                                <Alert severity="info" sx={{ mt: 1 }}>
                                    Creating a reservation or joining a room will remove you from
                                    the waiting list.
                                </Alert>
                            </>
                        )}
                    </>
                ) : (
                    <Typography>Room reservations system is closed.</Typography>
                )}
            </CardContent>
            {state === "open" && (
                <CardActions disableSpacing>
                    <Stack
                        spacing={1}
                        direction={{ xs: "column", md: "row" }}
                        sx={{ width: "100%" }}
                    >
                        <RoomTypeButton
                            label="Create Reservation"
                            emptyLabel="Booked Out"
                            onSelect={handleReservationCreation}
                            loading={createReservationMutation.isPending}
                            onlyDirectBooking
                            excludeUnavailable
                        />
                        <JoinButton sx={{ mr: 1 }} />
                        <RoomTypeButton
                            label="Join Waiting List"
                            onSelect={handleWaitingSpotCreation}
                            excludedRoomTypes={waitingSpots.map(
                                (waitingSpot) => waitingSpot.roomType,
                            )}
                            loading={createReservationMutation.isPending}
                            onlyDirectBooking
                        />

                        <Button
                            onClick={() => {
                                searchesDialogController.open();
                            }}
                        >
                            Room Mate Search
                        </Button>
                    </Stack>

                    {searchesDialogController.mount && (
                        <RoomMateSearchesDialog
                            dialogProps={searchesDialogController.dialogProps}
                        />
                    )}
                </CardActions>
            )}
        </Card>
    );
};

export default NewReservationCard;
