import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import type { Finisher } from "@/pages/PaymentPage/utils.ts";
import type { PaymentType } from "@/queries/payment.ts";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useMemo, useState } from "react";
import BankWireTransfer from "./BankWireTransfer";
import CreditCard from "./CreditCard";
import Sofort from "./Sofort";

type Props = {
    remainingAmount: number;
    onInit: () => void;
    onFinish: Finisher;
};

const PaymentTabs = ({ remainingAmount, onInit, onFinish }: Props): JSX.Element => {
    const [paymentMethod, setPaymentMethod] = useState<PaymentType>("card");
    const { publicStripeKey } = useRegistrationSpecs();
    const stripe = useMemo(async () => loadStripe(publicStripeKey), [publicStripeKey]);

    return (
        <Elements stripe={stripe}>
            <Paper>
                <Tabs
                    value={paymentMethod}
                    onChange={(_event, value) => {
                        setPaymentMethod(value as PaymentType);
                    }}
                >
                    <Tab value="card" label="Credit Card" />
                    <Tab value="sofort" label="SOFORT" />
                    <Tab value="bank_transfer" label="Bank Transfer" />
                </Tabs>

                <Box sx={{ p: 2, pt: 3 }}>
                    {paymentMethod === "card" && (
                        <CreditCard
                            amountToPay={remainingAmount}
                            onInit={onInit}
                            onFinish={onFinish}
                        />
                    )}
                    {paymentMethod === "sofort" && (
                        <Sofort amountToPay={remainingAmount} onInit={onInit} onFinish={onFinish} />
                    )}
                    {paymentMethod === "bank_transfer" && (
                        <BankWireTransfer onInit={onInit} onFinish={onFinish} />
                    )}
                </Box>
            </Paper>
        </Elements>
    );
};

export default PaymentTabs;
