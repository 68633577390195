import { useEnsuredRegistration } from "@/components/RegistrationProvider/index.ts";
import { useSession } from "@/components/SessionProvider/index.ts";
import {
    useCancelPaymentIntentMutation,
    useCreatePaymentIntentMutation,
} from "@/mutations/payment-intent.ts";
import type { PaymentIntent } from "@/queries/payment-intent.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { formatCents } from "@/utils/format.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, MenuItem, Typography } from "@mui/material";
import { useStripe } from "@stripe/react-stripe-js";
import { RhfTextField } from "mui-rhf-integration";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import type { Finisher } from "../utils.ts";

const supportedCountryCodes = ["AT", "BE", "DE", "IT", "NL", "ES"] as const;

const schema = z.object({
    countryCode: z.enum(supportedCountryCodes),
});

type FormValues = z.infer<typeof schema>;

type Props = {
    amountToPay: number;
    onInit: () => void;
    onFinish: Finisher;
};

const Sofort = ({ amountToPay, onInit, onFinish }: Props): JSX.Element => {
    const { identity } = useSession();
    const registration = useEnsuredRegistration();
    const stripe = useStripe();
    const createPaymentIntentMutation = useCreatePaymentIntentMutation();
    const cancelPaymentIntentMutation = useCancelPaymentIntentMutation();

    const defaultCountryCode = useMemo(() => {
        if (
            (supportedCountryCodes as unknown as string[]).includes(registration.person.countryCode)
        ) {
            return registration.person.countryCode as (typeof supportedCountryCodes)[number];
        }

        return undefined;
    }, [registration.person.countryCode]);

    const form = useForm<FormValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            countryCode: defaultCountryCode,
        },
    });

    const handleSubmit = async (values: FormValues) => {
        if (!stripe) {
            return;
        }

        onInit();
        let paymentIntent: PaymentIntent;

        try {
            paymentIntent = await createPaymentIntentMutation.mutateAsync({
                attributes: {
                    paymentMethodType: "sofort",
                },
            });
        } catch (error) {
            onFinish({ type: "failure", message: getErrorMessage(error) });
            return;
        }

        if (!paymentIntent.clientSecret) {
            throw new Error("Payment intent is missing client secret");
        }

        const { paymentIntent: stripePaymentIntent, error } = await stripe.confirmSofortPayment(
            paymentIntent.clientSecret,
            {
                payment_method: {
                    billing_details: {
                        name: `${registration.person.firstName} ${registration.person.lastName}`,
                        email: identity.emailAddress,
                    },
                    sofort: {
                        country: values.countryCode,
                    },
                },
                return_url: `${window.location.origin}/payment`,
            },
        );

        if (!error) {
            onFinish({ type: "payment_intent_status", status: stripePaymentIntent.status });
            return;
        }

        cancelPaymentIntentMutation.mutate(
            {
                id: paymentIntent.id,
            },
            {
                onSuccess: () => {
                    onFinish({
                        type: "failure",
                        message: error.message ?? "Failed to process your payment",
                    });
                },
                onError: (error) => {
                    onFinish({ type: "failure", message: getErrorMessage(error) });
                },
            },
        );
    };

    return (
        <>
            <Typography mb={3}>
                Transfer money from a supported European bank account via PIN & TAN.
            </Typography>

            <form onSubmit={form.handleSubmit(handleSubmit)} noValidate>
                <div>
                    <RhfTextField
                        control={form.control}
                        label="Country of Bank"
                        name="countryCode"
                        required
                        select
                        fullWidth
                    >
                        <MenuItem value="AT">Austria</MenuItem>
                        <MenuItem value="BE">Belgium</MenuItem>
                        <MenuItem value="DE">Germany</MenuItem>
                        <MenuItem value="IT">Italy</MenuItem>
                        <MenuItem value="NL">Netherlands</MenuItem>
                        <MenuItem value="ES">Spain</MenuItem>
                    </RhfTextField>
                </div>

                <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                    Pay {formatCents(amountToPay)}
                </Button>
            </form>
        </>
    );
};

export default Sofort;
