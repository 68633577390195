import { MenuItem } from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import type { ReactNode } from "react";
import type { Control, FieldPathByValue, FieldValues } from "react-hook-form";
import { z } from "zod";

type Option = {
    value: string;
    label: string;
};

const tShirtSizeOptions = [
    { value: "s", label: "Small" },
    { value: "m", label: "Medium" },
    { value: "l", label: "Large" },
    { value: "xl", label: "X-Large" },
    { value: "xxl", label: "XX-Large" },
    { value: "3xl", label: "3X-Large" },
    { value: "4xl", label: "4X-Large" },
] as const satisfies Option[];

export type TShirtSize = (typeof tShirtSizeOptions)[number]["value"];

export const getTShirtSizeLabel = (size: TShirtSize): string => {
    const option = tShirtSizeOptions.find((option) => option.value === size);

    if (!option) {
        throw new Error(`Unknown T-shirt size: ${size}`);
    }

    return option.label;
};

export const tShirtSizeSchema = z.enum(
    tShirtSizeOptions.map((option) => option.value) as [TShirtSize, ...TShirtSize[]],
);

type Props<
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, TShirtSize | null | undefined>,
> = {
    control: Control<TFieldValues>;
    name: TName;
    disabled?: boolean;
};

const TShirtSizeSelect = <
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, TShirtSize | null | undefined>,
>({
    control,
    name,
    disabled,
}: Props<TFieldValues, TName>): ReactNode => {
    return (
        <RhfTextField
            control={control}
            name={name}
            select
            fullWidth
            required={!disabled}
            disabled={disabled}
            label="T-shirt size"
        >
            {tShirtSizeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </RhfTextField>
    );
};

export default TShirtSizeSelect;
