import useDialogController from "@/hooks/useDialogController.ts";
import AddIcon from "@mui/icons-material/Add";
import BedIcon from "@mui/icons-material/Hotel";
import { IconButton, ListItem, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import type { ReactNode } from "react";
import InviteFormDialog from "./InviteFormDialog.tsx";

type Props = {
    inviteAllowed: boolean;
};

const FreeBedListItem = ({ inviteAllowed }: Props): ReactNode => {
    const inviteDialogController = useDialogController();

    return (
        <ListItem>
            <ListItemIcon>
                <BedIcon />
            </ListItemIcon>
            <ListItemText primary={inviteAllowed ? "Add Room Mate" : "Free Bed"} />
            {inviteAllowed && (
                <Tooltip title="Add Room Mate">
                    <IconButton
                        edge="end"
                        onClick={() => {
                            inviteDialogController.open();
                        }}
                        size="small"
                    >
                        <AddIcon />
                    </IconButton>
                </Tooltip>
            )}

            {inviteDialogController.mount && (
                <InviteFormDialog dialogProps={inviteDialogController.dialogProps} />
            )}
        </ListItem>
    );
};

export default FreeBedListItem;
