import useApiFetch from "@/hooks/useApiFetch.ts";
import { type Invoice, invoiceUrlSelector } from "@/queries/invoice.ts";
import { apiUrl } from "@/utils/api.ts";
import { dateFormatter, formatCents } from "@/utils/format.ts";
import DownloadIcon from "@mui/icons-material/Download";
import { Backdrop, CircularProgress, IconButton, ListItem, ListItemText } from "@mui/material";
import { handleJsonApiError } from "jsonapi-zod-query";
import { useState } from "react";
import type { ReactNode } from "react";

type Props = {
    invoice: Invoice;
};

const InvoiceListItem = ({ invoice }: Props): ReactNode => {
    const fetch = useApiFetch();
    const [loading, setLoading] = useState(false);

    const handleDownload = async () => {
        setLoading(true);

        try {
            const url = apiUrl(`/registration/registrations/self/invoices/${invoice.id}/url`);
            const response = await fetch(url);
            await handleJsonApiError(response);
            const result = invoiceUrlSelector(await response.json());
            window.open(result.url, "_blank", "noreferrer");
        } finally {
            setLoading(false);
        }
    };

    return (
        <ListItem disablePadding>
            <ListItemText
                primary={`#${invoice.invoiceNumber}`}
                secondary={`${invoice.date.format(dateFormatter)} / ${formatCents(invoice.amount)}`}
            />
            <IconButton
                onClick={() => {
                    void handleDownload();
                }}
            >
                <DownloadIcon />
            </IconButton>

            <Backdrop open={loading}>
                <CircularProgress />
            </Backdrop>
        </ListItem>
    );
};

export default InvoiceListItem;
