import { getTShirtSizeLabel } from "@/components/Registration/index.ts";
import type { RegistrationState } from "@/components/RegistrationSpecsProvider/RegistrationSpecsProvider.tsx";
import useDialogController from "@/hooks/useDialogController.ts";
import { useUpdateProductMutation } from "@/mutations/product.ts";
import type { Product } from "@/queries/product.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { formatCents } from "@/utils/format.ts";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton, ListItem, ListItemText } from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import DeleteAction from "./DeleteAction.tsx";
import TShirtSizeDialog from "./TShirtSizeDialog.tsx";
import { productNames } from "./utils.ts";

type Props = {
    product: Product;
    state: RegistrationState;
    hasGoldSponsorUpgrade: boolean;
    removableAmount: number;
};

const isDeletable = (
    product: Product,
    state: RegistrationState,
    hasGoldSponsorUpgrade: boolean,
    removableAmount: number,
): boolean => {
    if (state === "over" || product.amount > removableAmount) {
        return false;
    }

    return (
        product.type === "gold_sponsor_upgrade" ||
        (product.type === "silver_sponsor_upgrade" && !hasGoldSponsorUpgrade) ||
        (product.type === "t_shirt" && !product.configuration.goldSponsorAttached)
    );
};

const ProductsListItem = ({
    product,
    state,
    hasGoldSponsorUpgrade,
    removableAmount,
}: Props): ReactNode => {
    const updateMutation = useUpdateProductMutation();
    const updateDialogController = useDialogController();
    const { enqueueSnackbar } = useSnackbar();

    let name = productNames[product.type];

    if (product.type === "t_shirt") {
        name += ` (${getTShirtSizeLabel(product.configuration.size)})`;
    }

    return (
        <ListItem key={product.id} disablePadding>
            <ListItemText primary={name} secondary={formatCents(product.amount)} />

            {state !== "over" && product.type === "t_shirt" && (
                <IconButton
                    onClick={() => {
                        updateDialogController.open();
                    }}
                >
                    <EditIcon />
                </IconButton>
            )}

            {isDeletable(product, state, hasGoldSponsorUpgrade, removableAmount) && (
                <DeleteAction product={product} />
            )}

            {updateDialogController.mount && product.type === "t_shirt" && (
                <TShirtSizeDialog
                    dialogProps={updateDialogController.dialogProps}
                    title="Change T-shirt Size"
                    submitButtonLabel="Save"
                    defaultValue={product.configuration.size}
                    onSubmit={(values) => {
                        updateMutation.mutate(
                            {
                                id: product.id,
                                attributes: {
                                    type: "t_shirt",
                                    tShirtSize: values.tShirtSize,
                                },
                            },
                            {
                                onSuccess: () => {
                                    updateDialogController.dialogProps.onClose();
                                },
                                onError: (error) => {
                                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                                },
                            },
                        );
                    }}
                    isPending={updateMutation.isPending}
                />
            )}
        </ListItem>
    );
};

export default ProductsListItem;
