import FlagIcon from "@/components/FlagIcon/index.ts";
import { useEnsuredRegistration } from "@/components/RegistrationProvider/index.ts";
import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import { useSession } from "@/components/SessionProvider/index.ts";
import UserAvatar from "@/components/UserAvatar/index.ts";
import useDialogController from "@/hooks/useDialogController.ts";
import { formatRegistrationNumber } from "@/utils/format.ts";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Stack,
    Typography,
} from "@mui/material";
import { countries } from "countries-list";
import type { ReactNode } from "react";
import EditBadgeDialog from "./EditBadgeDialog.tsx";

const BadgeCard = (): ReactNode => {
    const {
        registration: { state },
    } = useRegistrationSpecs();
    const { identity } = useSession();
    const registration = useEnsuredRegistration();
    const dialogController = useDialogController();

    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardHeader
                avatar={<UserAvatar identity={identity} />}
                title={identity.nickname}
                subheader={formatRegistrationNumber(registration.registrationNumber)}
            />
            <CardContent>
                <Stack spacing={0.5}>
                    {registration.flagCodes.map((countryCode) => (
                        <Typography key={countryCode}>
                            <FlagIcon countryCode={countryCode} sx={{ mr: 2 }} />
                            {countries[countryCode].name}
                        </Typography>
                    ))}
                </Stack>
            </CardContent>
            <CardActions sx={{ mt: "auto" }}>
                <Button
                    disabled={state === "over"}
                    onClick={() => {
                        dialogController.open();
                    }}
                >
                    Customize your badge
                </Button>
            </CardActions>

            {dialogController.mount && (
                <EditBadgeDialog dialogProps={dialogController.dialogProps} />
            )}
        </Card>
    );
};

export default BadgeCard;
