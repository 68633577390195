import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
    checked: boolean;
    checkedLabel: string;
    uncheckedLabel: string;
};

const CheckListItem = ({ checked, checkedLabel, uncheckedLabel }: Props): ReactNode => {
    return (
        <ListItem disablePadding>
            <ListItemIcon>
                <InfoOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary={checked ? checkedLabel : uncheckedLabel} />
        </ListItem>
    );
};

export default CheckListItem;
