import type { ControlledDialogProps } from "@/hooks/useDialogController.ts";
import {
    useDeleteRoomMateSearchMutation,
    useReplaceRoomMateSearchMutation,
} from "@/mutations/room-mate-search.ts";
import { useRoomMateSearchQuery } from "@/queries/room-mate-search.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import { type ReactNode, useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    content: z.string().trim().min(1),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
};

const RoomMateSearchDialog = ({ dialogProps }: Props): ReactNode => {
    const searchQuery = useRoomMateSearchQuery();
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
    });
    const replaceMutation = useReplaceRoomMateSearchMutation();
    const deleteMutation = useDeleteRoomMateSearchMutation();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        form.reset({ content: searchQuery.data?.content ?? "" });
    }, [searchQuery.data, form.reset]);

    if (searchQuery.isError) {
        throw searchQuery.error;
    }

    if (searchQuery.isPending) {
        return (
            <Backdrop open>
                <CircularProgress />
            </Backdrop>
        );
    }

    const handleSubmit = (values: TransformedValues) => {
        replaceMutation.mutate(
            { attributes: values },
            {
                onSuccess: () => {
                    enqueueSnackbar("You have posted your room mate search", {
                        variant: "success",
                    });
                    dialogProps.onClose();
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    const handleDelete = () => {
        deleteMutation.mutate(undefined, {
            onSuccess: () => {
                enqueueSnackbar("You have deleted your room mate search", { variant: "success" });
                dialogProps.onClose();
            },
            onError: (error) => {
                enqueueSnackbar(getErrorMessage(error), { variant: "error" });
            },
        });
    };

    return (
        <Dialog
            {...dialogProps}
            PaperProps={{
                component: "form",
                onSubmit: form.handleSubmit(handleSubmit),
                noValidate: true,
            }}
            maxWidth="sm"
            fullWidth
            fullScreen={fullScreen}
        >
            <DialogTitle>Room Mate Search</DialogTitle>
            <DialogContent dividers>
                <Typography mb={2}>
                    If you have a free bed in your room and are looking for a room mate, you can
                    post a search here. Your listing will automatically include your nickname,
                    profile picture and the room type of your reservation.
                </Typography>
                <RhfTextField
                    control={form.control}
                    name="content"
                    label="Description"
                    helperText="Include some form of contact information"
                    multiline
                    minRows={5}
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        dialogProps.onClose();
                    }}
                    color="inherit"
                >
                    Cancel
                </Button>
                {searchQuery.data && (
                    <LoadingButton
                        loading={deleteMutation.isPending}
                        onClick={() => {
                            handleDelete();
                        }}
                        color="error"
                    >
                        Delete
                    </LoadingButton>
                )}
                <Button type="submit" color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default RoomMateSearchDialog;
