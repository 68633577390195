import type { PaymentIntentStatus } from "@/queries/payment-intent.ts";

type PaymentIntentStatusResult = {
    type: "payment_intent_status";
    status: PaymentIntentStatus;
};

type PaymentIntentIdResult = {
    type: "payment_intent_id";
    id: string;
};

type FailureResult = {
    type: "failure";
    message: string;
};

type AbortedResult = {
    type: "aborted";
};

type FinisherResult =
    | PaymentIntentStatusResult
    | PaymentIntentIdResult
    | FailureResult
    | AbortedResult;

export type Finisher = (result: FinisherResult) => void;

export const paymentTypes = {
    card: "Credit Card",
    sofort: "SOFORT",
    bank_transfer: "Bank Transfer",
} as const;
