import { apiUrl } from "@/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { TCountryCode } from "countries-list";
import {
    type Relationships,
    createDataSelector,
    createNullableResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

export const registrationAttributesSchema = z.object({
    registrationNumber: z.number(),
    person: z.object({
        firstName: z.string(),
        lastName: z.string(),
        streetAddressLineOne: z.string(),
        streetAddressLineTwo: z.string(),
        city: z.string(),
        stateOrRegion: z.string(),
        postalCode: z.string(),
        countryCode: z.string().transform((value) => value as TCountryCode),
        dateOfBirth: zj.localDate(),
        telegramUsername: z.string(),
    }),
    flagCodes: z.array(z.string().transform((value) => value as TCountryCode)),
    details: z.object({
        firstFurryCon: z.boolean(),
        fursuiter: z.boolean(),
    }),
    paymentSummary: z.object({
        dueOn: zj.localDate(),
        completedOn: zj.localDate().nullable(),
        totalAmount: z.number(),
        pendingAmount: z.number(),
        paidAmount: z.number(),
        hasFullPendingAmount: z.boolean(),
        remainingAmount: z.number(),
        openAmount: z.number(),
        reminderSent: z.boolean(),
        stripeCustomerId: z.string().nullable(),
    }),
    publicListingConsent: z.boolean(),
});

const speciesCategoryRelationship = {
    relationshipType: "one_nullable",
    include: {
        type: "species_category",
        attributesSchema: z.object({
            name: z.string(),
            description: z.string(),
        }),
    },
} satisfies Relationships[string];

export const publicOnlyRegistrationAttributesSchema = z.object({
    registrationNumber: z.number(),
    flagCodes: z.array(z.string().transform((value) => value as TCountryCode)),
    details: z.object({
        fursuiter: z.boolean(),
    }),
});

export const publicOnlyRegistrationRelationships = {
    identity: {
        relationshipType: "one_nullable",
        include: {
            type: "identity",
            attributesSchema: z.object({
                nickname: z.string(),
                picture: z
                    .object({
                        small: z.string(),
                        normal: z.string(),
                        original: z.string(),
                    })
                    .nullable(),
            }),
        },
    },
    speciesCategory: speciesCategoryRelationship,
} satisfies Relationships;

const relationships = {
    speciesCategory: speciesCategoryRelationship,
} satisfies Relationships;

const registrationSelector = createDataSelector(
    createNullableResourceSelector({
        type: "registration",
        attributesSchema: registrationAttributesSchema,
        relationships,
    }),
);

export type Registration = NonNullable<ReturnType<typeof registrationSelector>>;

export const useSelfRegistrationQuery = (): UseQueryResult<Registration | null> => {
    return useQuery({
        queryKey: ["registration", "self"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/registration/registrations/self"), {
                signal,
                credentials: "include",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        staleTime: 60 * 15 * 1000,
        select: registrationSelector,
    });
};
