import type { SxProps } from "@mui/material";
import { Avatar } from "@mui/material";

type Identity = {
    nickname: string;
    picture: {
        small: string;
        normal: string;
        original: string;
    } | null;
};

export type UserAvatarProps = {
    identity: Identity;
    variant?: "circular" | "rounded" | "square";
    size?: number;
    sx?: SxProps;
};

const UserAvatar = ({ identity, variant, size = 40, sx }: UserAvatarProps): JSX.Element => {
    const localSx = {
        ...sx,
        width: size,
        height: size,
        fontSize: `calc((${size} / 40) * 1.25rem)`,
    };

    if (!identity.picture) {
        return (
            <Avatar sx={localSx} variant={variant}>
                {identity.nickname[0].toUpperCase()}
            </Avatar>
        );
    }

    let url: string;

    if (!size || size <= 64) {
        url = identity.picture.small;
    } else if (size <= 128) {
        url = identity.picture.normal;
    } else {
        url = identity.picture.original;
    }

    return <Avatar src={url} sx={localSx} variant={variant} alt={identity.nickname} />;
};

export default UserAvatar;
