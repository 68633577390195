import { Link, Typography } from "@mui/material";
import { RhfRadioGroup } from "mui-rhf-integration";
import type { ReactNode } from "react";
import type { Control, FieldPathByValue, FieldValues } from "react-hook-form";

type Props<
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, "yes" | "no">,
> = {
    control: Control<TFieldValues>;
    name: TName;
};

const PublicListingConsent = <
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, "yes" | "no">,
>({
    control,
    name,
}: Props<TFieldValues, TName>): ReactNode => {
    return (
        <>
            <Typography paragraph>
                We would like to list you on our{" "}
                <Link
                    href="https://furvester.org/attending/attendees"
                    target="_blank"
                    rel="noreferrer"
                >
                    public attendee page
                </Link>
                .
            </Typography>
            <Typography paragraph>
                This includes your nickname, profile picture, sponsor level, species category and
                your country of origin or flags selected to be displayed on your badge.
            </Typography>

            <RhfRadioGroup
                control={control}
                name={name}
                label={"Do you consent to this listing?"}
                options={[
                    { value: "yes", label: "Yes" },
                    { value: "no", label: "No" },
                ]}
                radioGroupProps={{ row: true }}
            />
        </>
    );
};

export default PublicListingConsent;
