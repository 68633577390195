import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import { formatCents } from "@/utils/format.ts";
import PersonIcon from "@mui/icons-material/Person";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import {
    Avatar,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Grid,
    Typography,
    styled,
} from "@mui/material";
import { type ReactNode, useMemo } from "react";
import {
    type Control,
    type FieldPathByValue,
    type FieldValues,
    useController,
} from "react-hook-form";

const StyledCardActionArea = styled(CardActionArea)({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
});

type SponsorLevel = "silver" | "gold" | null;

type Props<
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, SponsorLevel>,
> = {
    control: Control<TFieldValues>;
    name: TName;
};

const unselectedBorder = "2px solid transparent";

const TicketSelection = <
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, SponsorLevel>,
>({
    control,
    name,
}: Props<TFieldValues, TName>): ReactNode => {
    const registrationSpecs = useRegistrationSpecs();
    const { field } = useController({ control, name });

    const ticketPrices = useMemo(() => {
        const attendee = registrationSpecs.prices.fullTicket + registrationSpecs.prices.stageTicket;
        const silverSponsor = attendee + registrationSpecs.prices.silverSponsor;
        const goldSponsor = attendee + registrationSpecs.prices.goldSponsor;

        return { attendee, silverSponsor, goldSponsor };
    }, [registrationSpecs.prices]);

    return (
        <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
                <Card
                    sx={{
                        height: "100%",
                        border: (theme) =>
                            field.value === null
                                ? `2px solid ${theme.palette.primary.main}`
                                : unselectedBorder,
                    }}
                >
                    <StyledCardActionArea
                        onClick={() => {
                            field.onChange(null);
                        }}
                    >
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            }
                            title="Attendee"
                            subheader={formatCents(ticketPrices.attendee)}
                        />
                        <CardContent>
                            <Typography>
                                The basic entry ticket which grants you access to the convention.
                            </Typography>
                        </CardContent>
                    </StyledCardActionArea>
                </Card>
            </Grid>
            <Grid item md={4} xs={12}>
                <Card
                    sx={{
                        height: "100%",
                        border: (theme) =>
                            field.value === "silver"
                                ? `2px solid ${theme.palette.primary.main}`
                                : unselectedBorder,
                    }}
                >
                    <StyledCardActionArea
                        onClick={() => {
                            field.onChange("silver");
                        }}
                    >
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <PersonAddAlt1Icon />
                                </Avatar>
                            }
                            title="Silver Sponsor"
                            subheader={formatCents(ticketPrices.silverSponsor)}
                        />
                        <CardContent>
                            <Typography>
                                Same as the Attendee ticket, but you'll receive a special lanyard
                                and badge, as well as a sponsor bag.
                            </Typography>
                        </CardContent>
                    </StyledCardActionArea>
                </Card>
            </Grid>
            <Grid item md={4} xs={12}>
                <Card
                    sx={{
                        height: "100%",
                        border: (theme) =>
                            field.value === "gold"
                                ? `2px solid ${theme.palette.primary.main}`
                                : unselectedBorder,
                    }}
                >
                    <StyledCardActionArea
                        onClick={() => {
                            field.onChange("gold");
                        }}
                    >
                        <CardHeader
                            avatar={
                                <Avatar>
                                    <PersonAddAlt1Icon />
                                </Avatar>
                            }
                            title="Gold Sponsor"
                            subheader={formatCents(ticketPrices.goldSponsor)}
                        />
                        <CardContent>
                            <Typography>
                                Same as the Silver Sponsor ticket, but you'll additionally receive a
                                free T-shirt and more perks in your sponsor bag.
                            </Typography>
                        </CardContent>
                    </StyledCardActionArea>
                </Card>
            </Grid>
        </Grid>
    );
};

export default TicketSelection;
