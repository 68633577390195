import type { RoomReservation } from "@/queries/room-reservation.ts";
import type { WaitingSpot } from "@/queries/waiting-spot.ts";
import type { ReactNode } from "react";
import ActiveReservationCard from "./ActiveReservationCard/index.ts";
import NewReservationCard from "./NewReservationCard/index.ts";

type Props = {
    reservation: RoomReservation | null;
    waitingSpots: WaitingSpot[];
};

const ReservationCard = ({ reservation, waitingSpots }: Props): ReactNode => {
    return reservation ? (
        <ActiveReservationCard reservation={reservation} />
    ) : (
        <NewReservationCard waitingSpots={waitingSpots} />
    );
};

export default ReservationCard;
