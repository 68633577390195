import { useEnsuredRegistration } from "@/components/RegistrationProvider/index.ts";
import { dateFormatter } from "@/utils/format.ts";
import PaymentIcon from "@mui/icons-material/Payment";
import { Alert, Button, Card, CardActions, CardContent, CardHeader } from "@mui/material";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";

const PaymentCard = (): ReactNode => {
    const registration = useEnsuredRegistration();
    const { paymentSummary } = registration;

    let status = "open_amount";

    if (paymentSummary.paidAmount >= paymentSummary.totalAmount) {
        status = "paid";
    } else if (
        paymentSummary.paidAmount + paymentSummary.pendingAmount >=
        paymentSummary.totalAmount
    ) {
        status = "pending";
    }

    let cardContent: ReactNode;

    if (status === "open_amount") {
        cardContent = (
            <Alert severity="warning">
                You haven't paid yet. Your payment is due on{" "}
                {paymentSummary.dueOn.format(dateFormatter)}.
            </Alert>
        );
    } else if (status === "paid" && paymentSummary.completedOn) {
        cardContent = (
            <>
                You have successfully completed your payment on{" "}
                {paymentSummary.completedOn.format(dateFormatter)}.
            </>
        );
    } else {
        cardContent =
            "You have initiated your payment and we are awaiting confirmation from our payment provider.";
    }

    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardHeader avatar={<PaymentIcon />} title="Payment" />
            <CardContent>{cardContent}</CardContent>
            <CardActions sx={{ mt: "auto" }}>
                <Button component={Link} to="/payment">
                    {status === "open_amount" ? "Pay now" : "View payment history"}
                </Button>
            </CardActions>
        </Card>
    );
};

export default PaymentCard;
