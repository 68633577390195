import { useSession } from "@/components/SessionProvider/index.ts";
import { useRoomLotteryQuery } from "@/queries/room-lottery.ts";
import { useRoomReservationQuery } from "@/queries/room-reservation.ts";
import { useWaitingSpotsQuery } from "@/queries/waiting-spot.ts";
import { LinearProgress, Stack } from "@mui/material";
import { type ReactNode, Suspense, lazy, useMemo } from "react";
import ReservationCard from "./ReservationCard/index.ts";

const RoomLotteryInformation = lazy(() => import("./RoomLotteryInformation.tsx"));
const RoomLotteryCard = lazy(() => import("./RoomLotteryCard/index.ts"));

const RoomReservationSection = (): ReactNode => {
    const { permissions } = useSession();
    const skipLottery = useMemo(
        () => permissions.some((permission) => permission.id === "registration:skip-lottery"),
        [permissions],
    );
    const roomLotteryQuery = useRoomLotteryQuery();
    const roomReservationQuery = useRoomReservationQuery();
    const waitingSpotsQuery = useWaitingSpotsQuery();

    if (roomLotteryQuery.isError) {
        throw roomLotteryQuery.error;
    }

    if (roomReservationQuery.isError) {
        throw roomReservationQuery.error;
    }

    if (waitingSpotsQuery.isError) {
        throw waitingSpotsQuery.error;
    }

    if (
        roomLotteryQuery.isPending ||
        roomReservationQuery.isPending ||
        waitingSpotsQuery.isPending
    ) {
        return <LinearProgress />;
    }

    const roomLottery = roomLotteryQuery.data;
    const roomReservation = roomReservationQuery.data;
    const waitingSpots = waitingSpotsQuery.data;

    return (
        <Stack spacing={2}>
            {(roomReservation || !roomLottery || skipLottery) && (
                <ReservationCard reservation={roomReservation} waitingSpots={waitingSpots} />
            )}

            {roomLottery && (
                <Suspense fallback={<LinearProgress />}>
                    <RoomLotteryCard
                        hasReservation={Boolean(roomReservation)}
                        hasWaitingSpots={waitingSpots.length > 0}
                    />
                    <RoomLotteryInformation />
                </Suspense>
            )}
        </Stack>
    );
};

export default RoomReservationSection;
