import Layout from "@/components/Layout/Layout.tsx";
import RootErrorBoundary from "@/components/RootErrorBoundary/index.ts";
import HomePage from "@/pages/HomePage/index.ts";
import PaymentPage from "@/pages/PaymentPage/index.ts";
import { Navigate, type RouteObject, createBrowserRouter } from "react-router-dom";

const pathRoutes: RouteObject[] = [
    {
        index: true,
        element: (
            <Layout title="Registration">
                <HomePage />
            </Layout>
        ),
    },
    {
        path: "payment",
        element: (
            <Layout title="Payment" backTo="/">
                <PaymentPage />
            </Layout>
        ),
    },
    {
        path: "*",
        element: <Navigate to="/" />,
    },
];

const rootRoute: RouteObject = {
    path: "/",
    errorElement: <RootErrorBoundary />,
    children: pathRoutes,
};

export const router = createBrowserRouter([rootRoute]);

if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
}
