import { useTheme } from "@mui/material";
import type {
    CardCvcElementComponent,
    CardExpiryElementComponent,
    CardNumberElementComponent,
} from "@stripe/react-stripe-js";
import type { StripeElementBase, StripeElementChangeEvent } from "@stripe/stripe-js";
import type { HTMLAttributes, ReactNode } from "react";
import { forwardRef, useImperativeHandle, useState } from "react";

type Props = HTMLAttributes<HTMLInputElement> & {
    component: CardNumberElementComponent | CardExpiryElementComponent | CardCvcElementComponent;
    onRemoteChange?: (event: StripeElementChangeEvent) => void;
    onRemoteFocus?: () => void;
    onRemoteBlur?: () => void;
};

const StripeInput = forwardRef(
    (
        { component: Component, onRemoteChange, onRemoteFocus, onRemoteBlur, ...rest }: Props,
        ref,
    ): ReactNode => {
        const theme = useTheme();
        const [mountNode, setMountNode] = useState<StripeElementBase | null>(null);

        useImperativeHandle(
            ref,
            () => ({
                focus: () => {
                    if (!mountNode) {
                        return;
                    }

                    mountNode.focus();
                },
            }),
            [mountNode],
        );

        return (
            <Component
                {...rest}
                onReady={setMountNode}
                options={{
                    placeholder: "",
                    showIcon: true,
                    style: {
                        base: {
                            lineHeight: `${theme.typography.htmlFontSize * 1.4375}px`,
                            color: theme.palette.text.primary,
                            fontSize: `${theme.typography.htmlFontSize}px`,
                            fontFamily: theme.typography.fontFamily,
                        },
                        invalid: {
                            color: theme.palette.text.primary,
                        },
                    },
                }}
                onChange={onRemoteChange}
                onFocus={onRemoteFocus}
                onBlur={onRemoteBlur}
            />
        );
    },
);
StripeInput.displayName = "StripeInput";

export default StripeInput;
