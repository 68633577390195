import { Grid } from "@mui/material";
import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import type { ReactNode } from "react";
import StripeElementWrapper from "./StripeElementWrapper.tsx";

const StripeCard = (): ReactNode => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
                <StripeElementWrapper label="Card Number" component={CardNumberElement} />
            </Grid>
            <Grid item xs={7} md={3}>
                <StripeElementWrapper label="Expiry (MM / YY)" component={CardExpiryElement} />
            </Grid>
            <Grid item xs={5} md={3}>
                <StripeElementWrapper label="CVC" component={CardCvcElement} />
            </Grid>
        </Grid>
    );
};

export default StripeCard;
