import { Card, CardContent, CardHeader } from "@mui/material";
import type { ReactNode } from "react";
import InvoicesList from "./InvoicesList.tsx";

const InvoicesCard = (): ReactNode => {
    return (
        <Card sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <CardHeader title="Invoices" />
            <CardContent>
                <InvoicesList />
            </CardContent>
        </Card>
    );
};

export default InvoicesCard;
