import {
    publicOnlyRegistrationAttributesSchema,
    publicOnlyRegistrationRelationships,
} from "@/queries/registration.ts";
import { roomTypeAttributesSchema, roomTypeRelationships } from "@/queries/room-type.ts";
import { apiUrl } from "@/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
    type Relationships,
    createDataSelector,
    createNullableResourceSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const attributesSchema = z.object({
    content: z.string(),
});

const relationships = {
    reservation: {
        relationshipType: "one",
        include: {
            type: "room_reservation",
            upgradedCapacity: z.number().int().nullable(),
            relationships: {
                mainContact: {
                    relationshipType: "one",
                    include: {
                        type: "registration",
                        attributesSchema: publicOnlyRegistrationAttributesSchema,
                        relationships: publicOnlyRegistrationRelationships,
                    },
                },
                roomType: {
                    relationshipType: "one",
                    include: {
                        type: "room_type",
                        attributesSchema: roomTypeAttributesSchema,
                        relationships: roomTypeRelationships,
                    },
                },
            } satisfies Relationships,
        },
    },
} satisfies Relationships;

const roomMateSearchSelector = createDataSelector(
    createNullableResourceSelector({
        type: "room_mate_search",
        attributesSchema,
        relationships,
    }),
);

const roomMateSearchesSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "room_mate_search",
        attributesSchema,
        relationships,
    }),
);

export type RoomMateSearch = NonNullable<ReturnType<typeof roomMateSearchSelector>>;

export const useRoomMateSearchQuery = (): UseQueryResult<RoomMateSearch | null> => {
    return useQuery({
        queryKey: ["registration", "self", "room-reservation", "room-mate-search"],
        queryFn: async ({ signal }) => {
            const response = await fetch(
                apiUrl("/registration/registrations/self/room-reservation/room-mate-search"),
                {
                    signal,
                    credentials: "include",
                    headers: {
                        accept: "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);

            return response.json();
        },
        select: roomMateSearchSelector,
    });
};

export const useRoomMateSearchesQuery = (): UseQueryResult<RoomMateSearch[]> => {
    return useQuery({
        queryKey: ["room-mate-searches"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/registration/room-mate-searches"), {
                signal,
                credentials: "include",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: roomMateSearchesSelector,
    });
};
