import { useCreatePaymentIntentMutation } from "@/mutations/payment-intent.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { Button, Typography } from "@mui/material";
import type { Finisher } from "../utils.ts";

type Props = {
    onInit: () => void;
    onFinish: Finisher;
};

const BankWireTransfer = ({ onInit, onFinish }: Props): JSX.Element => {
    const createMutation = useCreatePaymentIntentMutation();

    const handleInit = () => {
        onInit();

        createMutation.mutate(
            {
                attributes: {
                    paymentMethodType: "bank_transfer",
                },
            },
            {
                onSuccess: (paymentIntent) => {
                    onFinish({ type: "payment_intent_id", id: paymentIntent.id });
                },
                onError: (error) => {
                    onFinish({ type: "failure", message: getErrorMessage(error) });
                },
            },
        );
    };

    return (
        <>
            <Typography mb={3}>
                If you are not able to pay with one of the other payment methods, you can also pay
                via bank wire transfer. Please note that this payment method can take up to two
                weeks to be confirmed.
            </Typography>

            <Button variant="contained" color="primary" onClick={handleInit}>
                Start bank wire transfer
            </Button>
        </>
    );
};

export default BankWireTransfer;
