import ConfirmDialog from "@/components/ConfirmDialog/index.ts";
import UserAvatar from "@/components/UserAvatar/index.ts";
import {
    useKickRoomMateMutation,
    useTransferOwnershipMutation,
} from "@/mutations/room-reservation.ts";
import type { RoomMate } from "@/queries/room-reservation.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { formatRegistrationNumber } from "@/utils/format.ts";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import RemoveIcon from "@mui/icons-material/Remove.js";
import KeyIcon from "@mui/icons-material/VpnKey.js";
import {
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useConfirm } from "react-confirm-hook";

type Props = {
    roomMate: RoomMate;
    actionsAllowed: boolean;
};

const RoomMateListItem = ({ roomMate, actionsAllowed }: Props): ReactNode => {
    const confirm = useConfirm(ConfirmDialog);
    const transferOwnershipMutation = useTransferOwnershipMutation();
    const kickRoomMateMutation = useKickRoomMateMutation();
    const { enqueueSnackbar } = useSnackbar();

    const { identity } = roomMate.registration;
    const name = identity
        ? identity.nickname
        : formatRegistrationNumber(roomMate.registration.registrationNumber);

    const handleOwnershipTransfer = () => {
        confirm({
            title: "Confirm Transfer",
            message: <>You are about to transfer ownership to {name}. Do you want to continue?</>,
            onConfirm: async () => {
                try {
                    await transferOwnershipMutation.mutateAsync({
                        roomMateId: roomMate.id,
                    });
                    enqueueSnackbar("Ownership has been transferred", { variant: "success" });
                } catch (error) {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                }
            },
        });
    };

    const handleKickRoomMate = () => {
        confirm({
            title: "Confirm Kick",
            message: <>You are about to kick {name} out of your room. Do you want to continue?</>,
            onConfirm: async () => {
                try {
                    await kickRoomMateMutation.mutateAsync({
                        id: roomMate.id,
                    });
                    enqueueSnackbar(`${name} has been kicked`, { variant: "success" });
                } catch (error) {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                }
            },
        });
    };

    return (
        <ListItem>
            {identity ? (
                <ListItemAvatar>
                    <UserAvatar identity={identity} />
                </ListItemAvatar>
            ) : (
                <ListItemIcon>
                    <AccountCircleIcon />
                </ListItemIcon>
            )}
            <ListItemText
                primary={name}
                primaryTypographyProps={{
                    sx: { overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" },
                }}
                secondary={roomMate.accepted ? "" : "Not yet accepted"}
            />
            {actionsAllowed && (
                <>
                    {roomMate.accepted && (
                        <Tooltip title="Transfer Ownership">
                            <IconButton onClick={handleOwnershipTransfer} size="large">
                                <KeyIcon />
                            </IconButton>
                        </Tooltip>
                    )}

                    <Tooltip title="Kick Room Mate">
                        <IconButton edge="end" onClick={handleKickRoomMate} size="large">
                            <RemoveIcon />
                        </IconButton>
                    </Tooltip>
                </>
            )}
        </ListItem>
    );
};

export default RoomMateListItem;
