import { useSession } from "@/components/SessionProvider/index.ts";
import UserAvatar from "@/components/UserAvatar/index.ts";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
    AppBar,
    Box,
    Container,
    Divider,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Stack,
    Toolbar,
    Typography,
} from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";

type Props = {
    title: string;
    backTo?: string;
    children: ReactNode;
};

const Layout = ({ title, backTo, children }: Props): ReactNode => {
    const userMenuState = usePopupState({ variant: "popover", popupId: "user-menu" });
    const session = useSession();

    return (
        <>
            <AppBar position="fixed">
                <Toolbar>
                    {backTo && (
                        <IconButton component={Link} to={backTo} sx={{ mr: 1 }} edge="start">
                            <ArrowBackIcon />
                        </IconButton>
                    )}

                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>

                    <IconButton edge="end" {...bindTrigger(userMenuState)}>
                        <UserAvatar identity={session.identity} />
                    </IconButton>

                    <Menu {...bindMenu(userMenuState)}>
                        <Box
                            sx={{
                                width: 390,
                                p: 2,
                            }}
                        >
                            <Stack direction="row" spacing={2} alignItems="center">
                                <UserAvatar identity={session.identity} size={64} />
                                <div>
                                    <Typography>{session.identity.nickname}</Typography>
                                    <Typography variant="body2">
                                        {session.identity.emailAddress}
                                    </Typography>
                                </div>
                            </Stack>
                        </Box>

                        <Divider sx={{ mb: 1 }} />

                        <MenuItem
                            onClick={() => {
                                window.location.href = new URL(
                                    "/settings",
                                    import.meta.env.VITE_APP_IDENTITY_URL,
                                ).toString();
                            }}
                        >
                            <ListItemIcon>
                                <ManageAccountsIcon />
                            </ListItemIcon>
                            Settings
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                window.location.href = new URL(
                                    "/logout",
                                    import.meta.env.VITE_APP_IDENTITY_URL,
                                ).toString();
                            }}
                        >
                            <ListItemIcon>
                                <LogoutIcon />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            <Container component="main" sx={{ my: 2 }}>
                <Toolbar />
                {children}
            </Container>
        </>
    );
};

export default Layout;
