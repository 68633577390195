import { apiUrl } from "@/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
    createDataSelector,
    createNullableResourceSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";

const attributesSchema = z.object({
    state: z.enum(["open", "drawing", "closed"]),
});

const roomLotterySelector = createDataSelector(
    createNullableResourceSelector({
        type: "room_lottery",
        attributesSchema,
    }),
);

export type RoomLottery = NonNullable<ReturnType<typeof roomLotterySelector>>;

export const useRoomLotteryQuery = (): UseQueryResult<RoomLottery | null> => {
    return useQuery({
        queryKey: ["room-lottery", "active"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/registration/room-lotteries/active"), {
                signal,
                credentials: "include",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: roomLotterySelector,
    });
};
