import type { RoomMate } from "@/queries/room-reservation.ts";

export enum TimeFrame {
    SuperEarly = -2,
    Early = -1,
    Main = 0,
    Late = 1,
}

const collator = new Intl.Collator("en-US", { sensitivity: "base" });

export const compareRoomMates = (a: RoomMate, b: RoomMate): number => {
    const aIdentity = a.registration.identity;
    const bIdentity = b.registration.identity;

    if (aIdentity && bIdentity) {
        return collator.compare(aIdentity.nickname, bIdentity.nickname);
    }

    if (aIdentity && !bIdentity) {
        return -1;
    }

    if (!aIdentity && bIdentity) {
        return 1;
    }

    return a.registration.registrationNumber - b.registration.registrationNumber;
};

export type BookedTimeFrame = {
    arrival: number;
    departure: number;
};

export const hasStay = (bookedTimeFrame: BookedTimeFrame, timeFrame: TimeFrame): boolean =>
    timeFrame === 0 ||
    (timeFrame < 0 && bookedTimeFrame.arrival <= timeFrame) ||
    (timeFrame > 0 && bookedTimeFrame.departure >= timeFrame);
