import { Box, CircularProgress } from "@mui/material";
import type { ReactNode } from "react";

const FullPageSpinner = (): ReactNode => {
    return (
        <Box
            sx={{
                position: "absolute",
                left: 0,
                top: 0,
                display: "flex",
                height: "100%",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default FullPageSpinner;
