import { apiUrl } from "@/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const attributesSchema = z.object({
    createdAt: zj.zonedDateTime(),
    updatedAt: zj.zonedDateTime(),
    type: z.enum(["card", "sofort", "bank_transfer"]),
    status: z.enum(["init", "processing", "confirmed", "failed", "canceled"]),
    amount: z.number().int(),
});

const paymentsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "payment",
        attributesSchema,
        relationships: {
            paymentIntent: {
                relationshipType: "one",
                resourceType: "payment_intent",
            },
        },
    }),
);

export type Payment = ReturnType<typeof paymentsSelector>[number];
export type PaymentType = Payment["type"];

export const usePaymentsQuery = (): UseQueryResult<Payment[]> => {
    return useQuery({
        queryKey: ["registration", "self", "payments"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/registration/registrations/self/payments"), {
                signal,
                credentials: "include",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: paymentsSelector,
    });
};
