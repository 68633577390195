import {
    DetailsFieldset,
    PersonFieldset,
    SpeciesCategorySelect,
    detailsSchema,
    personSchema,
    speciesCategorySchema,
} from "@/components/Registration/index.ts";
import { useEnsuredRegistration } from "@/components/RegistrationProvider/index.ts";
import { useRegistrationSpecs } from "@/components/RegistrationSpecsProvider/index.ts";
import type { ControlledDialogProps } from "@/hooks/useDialogController.ts";
import { useUpdateRegistrationMutation } from "@/mutations/registration.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { convert } from "@js-joda/core";
import { LoadingButton } from "@mui/lab";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Stack,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { RhfSwitch } from "mui-rhf-integration";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    person: personSchema,
    details: detailsSchema,
    speciesCategoryId: speciesCategorySchema,
    publicListingConsent: z.boolean(),
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
};

const EditDetailsDialog = ({ dialogProps }: Props): ReactNode => {
    const registration = useEnsuredRegistration();
    const registrationSpecs = useRegistrationSpecs();
    const updateMutation = useUpdateRegistrationMutation();
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const maxDateOfBirth = useMemo(
        () => registrationSpecs.dates.arrival.minusYears(18),
        [registrationSpecs.dates.arrival],
    );

    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema, {
            errorMap: (error, context) => {
                if (error.code === "too_small") {
                    return { message: "You must select at least one flag" };
                }

                if (error.code === "too_big") {
                    return { message: "You can only select up to three flag" };
                }

                return { message: context.defaultError };
            },
        }),
        defaultValues: {
            person: {
                ...registration.person,
                dateOfBirth: convert(registration.person.dateOfBirth).toDate(),
            },
            details: registration.details,
            speciesCategoryId: registration.speciesCategory,
            publicListingConsent: registration.publicListingConsent,
        },
    });

    const handleSubmit = (values: TransformedValues) => {
        const { speciesCategoryId, ...attributes } = values;

        updateMutation.mutate(
            { attributes, speciesCategoryId },
            {
                onSuccess: () => {
                    enqueueSnackbar("Your details hve been updated", { variant: "success" });
                    dialogProps.onClose();
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <Dialog
            {...dialogProps}
            PaperProps={{
                component: "form",
                onSubmit: form.handleSubmit(handleSubmit),
                noValidate: true,
            }}
            maxWidth="md"
            fullWidth
            fullScreen={fullScreen}
        >
            <DialogTitle>Update Details</DialogTitle>
            <DialogContent dividers>
                <Stack spacing={4}>
                    <div>
                        <PersonFieldset
                            control={form.control}
                            name="person"
                            maxDateOfBirth={maxDateOfBirth}
                            isEdit
                        />
                    </div>
                    <SpeciesCategorySelect control={form.control} name="speciesCategoryId" />
                    <div>
                        <DetailsFieldset control={form.control} name="details" />
                        <FormControlLabel
                            control={
                                <RhfSwitch control={form.control} name="publicListingConsent" />
                            }
                            label="I consent to being listed on the website"
                        />
                    </div>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={dialogProps.onClose}
                    disabled={updateMutation.isPending}
                    color="inherit"
                >
                    Cancel
                </Button>
                <LoadingButton loading={updateMutation.isPending} type="submit" color="primary">
                    Update
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default EditDetailsDialog;
