import type { TShirtSize } from "@/components/Registration/TShirtSizeSelect.tsx";
import useApiFetch from "@/hooks/useApiFetch.ts";
import { apiUrl } from "@/utils/api.ts";
import { type UseMutationResult, useMutation, useQueryClient } from "@tanstack/react-query";
import { handleJsonApiError } from "jsonapi-zod-query";

type SilverSponsorAttributes = {
    type: "silver_sponsor";
};

type GoldSponsorAttributes = {
    type: "gold_sponsor";
    tShirtSize: TShirtSize;
};

type TShirtAttributes = {
    type: "t_shirt";
    tShirtSize: TShirtSize;
};

export type CreateProductValues = {
    attributes: SilverSponsorAttributes | GoldSponsorAttributes | TShirtAttributes;
};

export const useCreateProductMutation = (): UseMutationResult<void, Error, CreateProductValues> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(apiUrl("/registration/registrations/self/products"), {
                method: "POST",
                body: JSON.stringify({
                    data: {
                        type: "product",
                        ...values,
                    },
                }),
                headers: {
                    "Content-Type": "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ["registration", "self"], exact: true }),
                queryClient.invalidateQueries({ queryKey: ["registration", "self", "products"] }),
            ]);
        },
    });
};

type UpdateProductValues = {
    id: string;
    attributes: TShirtAttributes;
};

export const useUpdateProductMutation = (): UseMutationResult<void, Error, UpdateProductValues> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const { id, attributes } = values;

            const response = await fetch(
                apiUrl(`/registration/registrations/self/products/${id}`),
                {
                    method: "PATCH",
                    body: JSON.stringify({
                        data: {
                            type: "product",
                            id,
                            attributes,
                        },
                    }),
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: ["registration", "self", "products"] });
        },
    });
};

type DeleteProductValues = {
    id: string;
};

export const useDeleteProductMutation = (): UseMutationResult<void, Error, DeleteProductValues> => {
    const fetch = useApiFetch();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async (values) => {
            const response = await fetch(
                apiUrl(`/registration/registrations/self/products/${values.id}`),
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/vnd.api+json",
                    },
                },
            );
            await handleJsonApiError(response);
        },
        onSuccess: async () => {
            await Promise.all([
                queryClient.invalidateQueries({ queryKey: ["registration", "self"], exact: true }),
                queryClient.invalidateQueries({ queryKey: ["registration", "self", "products"] }),
            ]);
        },
    });
};
