type Handler = () => void;

type Task = {
    target: number;
    handler: Handler;
};

class Scheduler {
    private tasks = new Set<Task>();
    private interval: number | null = null;

    public scheduleTask(executeIn: number, handler: Handler) {
        this.tasks.add({ target: Date.now() + executeIn, handler: handler });

        if (this.tasks.size > 0) {
            this.startTimer();
        }
    }

    private startTimer() {
        this.interval = window.setInterval(() => {
            const now = Date.now();

            for (const task of this.tasks) {
                if (task.target > now) {
                    continue;
                }

                task.handler();
                this.tasks.delete(task);
            }

            if (this.tasks.size === 0 && this.interval !== null) {
                window.clearInterval(this.interval);
                this.interval = null;
            }
        }, 1000);
    }

    public shutdown() {
        if (this.interval !== null) {
            window.clearInterval(this.interval);
        }
    }
}

export default Scheduler;
