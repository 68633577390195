import type { Product } from "@/queries/product.ts";

export const productNames: Record<Product["type"], string> = {
    convention_ticket: "Convention Ticket",
    stage_ticket: "Stage Ticket",
    t_shirt: "T-shirt",
    silver_sponsor_upgrade: "Silver Sponsor Upgrade",
    gold_sponsor_upgrade: "Gold Sponsor Upgrade",
    discount: "Discount",
};
