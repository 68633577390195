import type { TShirtSize } from "@/components/Registration/TShirtSizeSelect.tsx";
import { TShirtSizeSelect, tShirtSizeSchema } from "@/components/Registration/index.ts";
import type { ControlledDialogProps } from "@/hooks/useDialogController.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import { LoadingButton } from "@mui/lab";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const schema = z.object({
    tShirtSize: tShirtSizeSchema,
});

type FieldValues = z.input<typeof schema>;
type TransformedValues = z.output<typeof schema>;

type Props = {
    dialogProps: ControlledDialogProps;
    title: string;
    description?: ReactNode;
    submitButtonLabel: string;
    defaultValue?: TShirtSize;
    onSubmit: (values: TransformedValues) => void;
    isPending: boolean;
};

const TShirtSizeDialog = ({
    dialogProps,
    title,
    description,
    submitButtonLabel,
    defaultValue,
    onSubmit,
    isPending,
}: Props): ReactNode => {
    const form = useForm<FieldValues, unknown, TransformedValues>({
        resolver: zodResolver(schema),
        defaultValues: {
            tShirtSize: defaultValue,
        },
    });

    return (
        <Dialog
            {...dialogProps}
            PaperProps={{
                component: "form",
                onSubmit: form.handleSubmit(onSubmit),
                noValidate: true,
            }}
            maxWidth="xs"
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent dividers>
                {description}
                <TShirtSizeSelect control={form.control} name="tShirtSize" />
            </DialogContent>
            <DialogActions>
                <Button onClick={dialogProps.onClose} disabled={isPending} color="inherit">
                    Cancel
                </Button>
                <LoadingButton loading={isPending} type="submit" color="primary">
                    {submitButtonLabel}
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default TShirtSizeDialog;
