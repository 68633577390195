import { DateTimeFormatter } from "@js-joda/core";
import { Locale } from "@js-joda/locale_en-us";

const currencyFormatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "EUR" });
export const formatCents = (cents: number): string => currencyFormatter.format(cents / 100);

export const formatRegistrationNumber = (number: number): string =>
    `#${number.toString().padStart(3, "0")}`;

export const dateTimeFormatter = DateTimeFormatter.ofPattern("dd.MM.yyyy 'at' HH:mm z").withLocale(
    Locale.US,
);
export const dateFormatter = DateTimeFormatter.ofPattern("dd.MM.yyyy");
