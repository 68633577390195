import { tShirtSizeSchema } from "@/components/Registration/index.ts";
import { apiUrl } from "@/utils/api.ts";
import type { UseQueryResult } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import {
    createDataSelector,
    createResourceCollectionSelector,
    handleJsonApiError,
} from "jsonapi-zod-query";
import { z } from "zod";
import { zj } from "zod-joda";

const attributesSchema = z
    .object({
        createdAt: zj.zonedDateTime(),
        updatedAt: zj.zonedDateTime(),
        index: z.number().int(),
        amount: z.number().int(),
    })
    .and(
        z.discriminatedUnion("type", [
            z.object({
                type: z.enum([
                    "convention_ticket",
                    "stage_ticket",
                    "silver_sponsor_upgrade",
                    "gold_sponsor_upgrade",
                    "discount",
                ]),
            }),
            z.object({
                type: z.literal("t_shirt"),
                configuration: z.object({
                    size: tShirtSizeSchema,
                    goldSponsorAttached: z.boolean(),
                }),
            }),
        ]),
    );

const productsSelector = createDataSelector(
    createResourceCollectionSelector({
        type: "product",
        attributesSchema,
    }),
);

export type Product = ReturnType<typeof productsSelector>[number];

export const useProductsQuery = (): UseQueryResult<Product[]> => {
    return useQuery({
        queryKey: ["registration", "self", "products"],
        queryFn: async ({ signal }) => {
            const response = await fetch(apiUrl("/registration/registrations/self/products"), {
                signal,
                credentials: "include",
                headers: {
                    accept: "application/vnd.api+json",
                },
            });
            await handleJsonApiError(response);

            return response.json();
        },
        select: productsSelector,
    });
};
