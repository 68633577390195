import { useEnsuredRegistration } from "@/components/RegistrationProvider/index.ts";
import { useSession } from "@/components/SessionProvider/index.ts";
import StripeCard from "@/components/Stripe/StripeCard";
import {
    useCancelPaymentIntentMutation,
    useCreatePaymentIntentMutation,
    useRefreshPaymentIntentStatusMutation,
} from "@/mutations/payment-intent.ts";
import type { PaymentIntent } from "@/queries/payment-intent.ts";
import { getErrorMessage } from "@/utils/api";
import { formatCents } from "@/utils/format.ts";
import { Button } from "@mui/material";
import { CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import type { FormEvent } from "react";
import type { Finisher } from "../utils.ts";

type Props = {
    amountToPay: number;
    onInit: () => void;
    onFinish: Finisher;
};

const CreditCard = ({ amountToPay, onInit, onFinish }: Props): JSX.Element => {
    const { identity } = useSession();
    const registration = useEnsuredRegistration();
    const stripe = useStripe();
    const elements = useElements();
    const createMutation = useCreatePaymentIntentMutation();
    const cancelMutation = useCancelPaymentIntentMutation();
    const refreshMutation = useRefreshPaymentIntentStatusMutation();

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        if (!(stripe && elements)) {
            return;
        }

        const cardNumberElement = elements.getElement(CardNumberElement);

        if (!cardNumberElement) {
            return;
        }

        onInit();

        const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: cardNumberElement,
            billing_details: {
                name: `${registration.person.firstName} ${registration.person.lastName}`,
                email: identity.emailAddress,
            },
        });

        if (paymentMethodError) {
            onFinish({ type: "failure", message: "Failed to create card payment" });
            return;
        }

        let paymentIntent: PaymentIntent;

        try {
            paymentIntent = await createMutation.mutateAsync({
                attributes: {
                    paymentMethodType: "card",
                },
            });
        } catch (error) {
            onFinish({ type: "failure", message: getErrorMessage(error) });
            return;
        }

        if (!paymentIntent.clientSecret) {
            onFinish({ type: "failure", message: "Failed to process your payment" });
            return;
        }

        const { error: cardError, paymentIntent: stripePaymentIntent } =
            await stripe.confirmCardPayment(paymentIntent.clientSecret, {
                payment_method: paymentMethod.id,
            });

        if (cardError) {
            cancelMutation.mutate(
                {
                    id: paymentIntent.id,
                },
                {
                    onSuccess: () => {
                        onFinish({
                            type: "failure",
                            message: cardError.message ?? "Failed to process your payment",
                        });
                    },
                    onError: (error) => {
                        onFinish({ type: "failure", message: getErrorMessage(error) });
                    },
                },
            );
            return;
        }

        try {
            const paymentIntentStatusOnly = await refreshMutation.mutateAsync({
                id: paymentIntent.id,
            });
            onFinish({ type: "payment_intent_status", status: paymentIntentStatusOnly.status });
        } catch (error) {
            onFinish({ type: "payment_intent_status", status: stripePaymentIntent.status });
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <StripeCard />
            <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                Pay {formatCents(amountToPay)}
            </Button>
        </form>
    );
};

export default CreditCard;
