import useDialogController from "@/hooks/useDialogController.ts";
import { useCancelPaymentIntentMutation } from "@/mutations/payment-intent.ts";
import BankTransferDialog from "@/pages/PaymentPage/BankTransferDialog.tsx";
import type { Payment } from "@/queries/payment.ts";
import { getErrorMessage } from "@/utils/api.ts";
import { dateFormatter, formatCents } from "@/utils/format.ts";
import CancelIcon from "@mui/icons-material/Cancel.js";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import InfoIcon from "@mui/icons-material/Info.js";
import PendingIcon from "@mui/icons-material/Pending";
import UpdateIcon from "@mui/icons-material/Update";
import {
    Backdrop,
    CircularProgress,
    IconButton,
    ListItem,
    ListItemIcon,
    ListItemText,
    Tooltip,
} from "@mui/material";
import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import { paymentTypes } from "../utils.ts";

const paymentStatusAttributes: Record<
    Payment["status"],
    {
        Icon: ReactNode;
        label: string;
    }
> = {
    init: { Icon: <PendingIcon color="warning" />, label: "Initiated" },
    processing: { Icon: <UpdateIcon color="info" />, label: "Processing" },
    confirmed: { Icon: <CheckIcon color="success" />, label: "Confirmed" },
    failed: { Icon: <ClearIcon color="error" />, label: "Failed" },
    canceled: { Icon: <ClearIcon />, label: "Canceled" },
};

type Props = {
    payment: Payment;
};

const PaymentListItem = ({ payment }: Props): ReactNode => {
    const cancelMutation = useCancelPaymentIntentMutation();
    const { enqueueSnackbar } = useSnackbar();
    const bankTransferDialogController = useDialogController();

    const handleCancel = () => {
        cancelMutation.mutate(
            {
                id: payment.paymentIntent.id,
            },
            {
                onSuccess: () => {
                    enqueueSnackbar("Payment has been canceled", { variant: "success" });
                },
                onError: (error) => {
                    enqueueSnackbar(getErrorMessage(error), { variant: "error" });
                },
            },
        );
    };

    return (
        <ListItem disablePadding>
            <ListItemIcon>{paymentStatusAttributes[payment.status].Icon}</ListItemIcon>
            <ListItemText
                primary={`${formatCents(payment.amount)} / ${
                    paymentStatusAttributes[payment.status].label
                }`}
                secondary={`${paymentTypes[payment.type]} / ${payment.createdAt.format(
                    dateFormatter,
                )}`}
            />
            {payment.status === "init" && payment.type === "bank_transfer" && (
                <Tooltip title="View Payment Information">
                    <IconButton
                        onClick={() => {
                            bankTransferDialogController.open();
                        }}
                    >
                        <InfoIcon />
                    </IconButton>
                </Tooltip>
            )}
            {payment.status === "init" && (
                <Tooltip title="Cancel Payment">
                    <IconButton onClick={handleCancel}>
                        <CancelIcon />
                    </IconButton>
                </Tooltip>
            )}

            {bankTransferDialogController.mount && (
                <BankTransferDialog
                    dialogProps={bankTransferDialogController.dialogProps}
                    paymentIntentId={payment.paymentIntent.id}
                />
            )}

            <Backdrop open={cancelMutation.isPending}>
                <CircularProgress />
            </Backdrop>
        </ListItem>
    );
};

export default PaymentListItem;
