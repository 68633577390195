import { dateSchema } from "@/utils/zod.ts";
import { LocalDate, convert } from "@js-joda/core";
import { Grid, InputAdornment } from "@mui/material";
import { RhfTextField } from "mui-rhf-integration";
import { RhfDatePicker } from "mui-rhf-integration/date-picker";
import type { ReactNode } from "react";
import type { Control, FieldPathByValue, FieldValues, Path } from "react-hook-form";
import { z } from "zod";
import CountrySelect from "./CountrySelect.tsx";

const minDateOfBirth = LocalDate.of(1900, 1, 1);

export const personSchema = z.object({
    firstName: z.string().trim().min(1),
    lastName: z.string().trim().min(1),
    streetAddressLineOne: z.string().trim().min(1),
    streetAddressLineTwo: z.string().trim().default(""),
    city: z.string().trim().min(1),
    stateOrRegion: z.string().trim().default(""),
    postalCode: z.string().trim().min(1),
    countryCode: z.string(),
    dateOfBirth: dateSchema.refine((value) => !value.isBefore(minDateOfBirth), "Invalid date"),
    telegramUsername: z
        .string()
        .trim()
        .default("")
        .transform((value) => value.replace(/^@ */, "")),
});

type Props<
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, z.input<typeof personSchema>>,
> = {
    control: Control<TFieldValues>;
    name: TName;
    maxDateOfBirth: LocalDate;
    isEdit?: boolean;
};

const PersonFieldset = <
    TFieldValues extends FieldValues,
    TName extends FieldPathByValue<TFieldValues, z.input<typeof personSchema>>,
>({
    control,
    name,
    maxDateOfBirth,
    isEdit,
}: Props<TFieldValues, TName>): ReactNode => {
    return (
        <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
                <RhfTextField
                    control={control}
                    label="First name"
                    name={`${name}.firstName` as Path<TFieldValues>}
                    required
                    fullWidth
                    disabled={isEdit}
                />
            </Grid>
            <Grid item sm={6} xs={12}>
                <RhfTextField
                    control={control}
                    label="Last name"
                    name={`${name}.lastName` as Path<TFieldValues>}
                    required
                    fullWidth
                    disabled={isEdit}
                />
            </Grid>
            <Grid item xs={12}>
                <RhfTextField
                    control={control}
                    label="Address"
                    name={`${name}.streetAddressLineOne` as Path<TFieldValues>}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item xs={12}>
                <RhfTextField
                    control={control}
                    name={`${name}.streetAddressLineTwo` as Path<TFieldValues>}
                    fullWidth
                />
            </Grid>
            <Grid item sm={4} xs={12}>
                <RhfTextField
                    control={control}
                    label="Postal code"
                    name={`${name}.postalCode` as Path<TFieldValues>}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item sm={4} xs={12}>
                <RhfTextField
                    control={control}
                    label="City"
                    name={`${name}.city` as Path<TFieldValues>}
                    required
                    fullWidth
                />
            </Grid>
            <Grid item sm={4} xs={12}>
                <RhfTextField
                    control={control}
                    label="State or region"
                    name={`${name}.stateOrRegion` as Path<TFieldValues>}
                    fullWidth
                />
            </Grid>
            <Grid item sm={4} xs={12}>
                <CountrySelect
                    control={control}
                    name={`${name}.countryCode` as Path<TFieldValues>}
                    textFieldProps={{
                        label: "Country",
                        required: true,
                        fullWidth: true,
                    }}
                />
            </Grid>
            <Grid item sm={4} xs={12}>
                <RhfDatePicker
                    control={control}
                    name={`${name}.dateOfBirth` as Path<TFieldValues>}
                    views={["year", "month", "day"]}
                    maxDate={convert(maxDateOfBirth).toDate()}
                    openTo="year"
                    format="dd.MM.yyyy"
                    label="Date of birth"
                    textFieldProps={{
                        required: true,
                        fullWidth: true,
                    }}
                />
            </Grid>
            <Grid item sm={4} xs={12}>
                <RhfTextField
                    control={control}
                    variant="outlined"
                    label="Telegram username"
                    name={`${name}.telegramUsername` as Path<TFieldValues>}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">@</InputAdornment>,
                    }}
                    fullWidth
                />
            </Grid>
        </Grid>
    );
};

export default PersonFieldset;
